import * as helpers from '&/utils/helpers';
import * as PfBase from '&/utils/base';
import baseCtrlMixIn from '&/utils/baseController';
import simplePager from '&/components/simplePager';
import Countdown from '&/components/Countdown';

const { $rootScope } = PfBase;
const hlp = helpers.appHelper;
const { Server } = helpers;

(() => { })(hlp.ConsoleLog, $rootScope);

const ctrlVideoFilterList = {
  name: 'ctrlVideoFilterList',
  el: '.ctrl-video-filter-list',
  components: { simplePager, Countdown },
  mixins: [baseCtrlMixIn],
  data() {
    return {
      videoFilterCollection: {
        resultFetched: false,
        pageList: [],
        pagerData: {},
      },
      selectedTags: [],
      isBusy: false,
      page: 1,
    };
  },
  mounted() {
    this.updateVideos();
    let scrollContainer = this.$el.querySelector('.scrollable-content'); // Adjust selector as needed
    if (!scrollContainer) scrollContainer = window; // Fallback to window if no container is found
    this.boundLoadMore = this.loadMore.bind(this);
    scrollContainer.addEventListener('scroll', this.boundLoadMore);
  },
  beforeDestroy() {
    let scrollContainer = this.$el.querySelector('.scrollable-content'); // Adjust selector as needed
    if (!scrollContainer) scrollContainer = window; // Fallback to window if no container is found
    scrollContainer.removeEventListener('scroll', this.boundLoadMore);
  },
  methods: {
    loadMore() {
      // Check if we're already loading or there's no more data to load
      if (this.isBusy || !this.videoFilterCollection.resultFetched) return;

      // Check scroll position
      const container = this.$el.querySelector('.scrollable-content') || document.documentElement;
      const scrollPosition = container.scrollTop + container.clientHeight;
      const bottomPosition = container.scrollHeight - 300; // Adjust this value as needed
      if (scrollPosition >= bottomPosition) {
        this.isBusy = true;
        this.page += 1;
        this.getVideos(this.selectedTags, this.page);
      }
    },
    getVideoCount() {
      if (!this.videoFilterCollection.pagerData || Object.keys(this.videoFilterCollection.pagerData).length === 0) {
        return 'No videos';
      }
      if (this.videoFilterCollection.pagerData.count === 1) {
        return `${this.videoFilterCollection.pagerData.count} video`;
      }
      return `${this.videoFilterCollection.pagerData.count} videos`;
    },
    updateVideos() {
      // Reinitialize videoFilterCollection before fetching new data
      this.videoFilterCollection.pageList = [];
      this.videoFilterCollection.pagerData = {};
      this.videoFilterCollection.resultFetched = false;
      this.page = 1;
      this.getVideos(this.selectedTags, this.page);
    },
    getVideos(ids, page) {
      Server.post('/api/get-video-list/', { ids, page })
        .then((result) => {
          if (result.pages.length > 0) {
            this.videoFilterCollection.pageList.push(...result.pages);
            this.videoFilterCollection.resultFetched = true;
            this.$set(this.videoFilterCollection, 'pagerData', result.pager_data);
          } else {
            this.videoFilterCollection.resultFetched = false;
          }
          this.isBusy = false;
        });
    },
    selectPage(newPage) {
      this.getVideos(this.selectedTags, newPage);
      this.videoFilterCollection.pagerData.page = newPage;
    },
    resetFilters() {
      this.selectedTags = [];
      this.page = 1;
      this.updateVideos();
    },
  },
};
export default ctrlVideoFilterList;
