import jquery from 'jquery';
import axios from 'axios';

const $ = jquery;

// const MONTH_NAMES_SHORT = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
window.$ = $;
jquery.easing.jswing = jquery.easing.swing;

axios.defaults.xsrfCookieName = 'csrftoken';
axios.defaults.xsrfHeaderName = 'X-CSRFToken';

jquery.extend(jquery.easing, {
  def: 'easeOutQuad',
  swing(x, t, b, c, d) {
    return jquery.easing[jquery.easing.def](x, t, b, c, d);
  },
  easeOutQuad(x, t, b, c, d) {
    // eslint-disable-next-line
    return -c * (t /= d) * (t - 2) + b;
  },
  easeOutQuart(x, t, b, c, d) {
    // eslint-disable-next-line
    return -c * ((t = t / d - 1) * t * t * t - 1) + b;
  },
});

class Deferred {
  constructor() {
    this.resolve = null;
    this.reject = null;
    this.promise = new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
    Object.freeze(this);
  }
}

function getModel(vnode) {
  let models;
  let directives;
  if (vnode.data && vnode.data.directives) {
    ({ directives } = vnode.data);
  } else if (vnode.directives) {
    ({ directives } = vnode);
  }
  if (directives) {
    models = directives.filter((v) => v.name === 'model');
    if (models.length) {
      return models[0].expression;
    }
  }
  return null;
}

function goToPage(url) {
  window.location.href = url;
}

const appHelper = {
  waitForIt(testFn) {
    const deferredObj = new Deferred();
    const fn = (nr) => {
      nr = nr || 0;
      if (testFn()) {
        deferredObj.resolve();
      } else {
        if (nr > 30) {
          deferredObj.reject();
          return;
        }
        setTimeout(() => {
          fn(nr + 1);
        }, 100);
      }
    };
    setTimeout(() => {
      fn(0);
    });
    return deferredObj.promise;
  },
  waitForElement(selector) {
    return appHelper.waitForIt(() => $(selector).length);
  },
  isScrolledIntoView(elem) {
    const $elem = $(elem);
    const $window = $(window);
    const docViewTop = $window.scrollTop();
    const docViewBottom = docViewTop + $window.height();
    const elemTop = $elem.offset().top;
    const elemBottom = elemTop + $elem.height();
    return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
  },
  notification: {
    success(msg) {
      // eslint-disable-next-line
      alert(msg);
    },
    error(msg) {
      // eslint-disable-next-line
      alert(msg);
    },
    warning(msg) {
      // eslint-disable-next-line
      alert(msg);
    },
  },
  capitalize(val) {
    if (val) {
      val = val[0].toUpperCase() + val.substr(1).toLowerCase();
    }
    return val;
  },

  maxMin(mx, mn, value) {
    return Math.max(mx, Math.min(mn, value));
  },
  consoleLog(...args) {
    // eslint-disable-next-line
    console.log(...args);
  },
  getAttrs(element) {
    const el = $(element)[0];
    const result = {};
    $.each(el.attributes, function eachFn() {
      // this.attributes is not a plain object, but an array
      // of attribute nodes, which contain both the name and value
      if (this.specified) {
        result[appHelper.kebabToCamel(this.name)] = this.value;
        // console.log(this.name, this.value);
      }
    });
    return result;
  },
  MathSign(x) {
    if (Math.sign) {
      return Math.sign(x);
    }
    // eslint-disable-next-line
    return (x < 0) ? -1 : ((x > 0) ? 1 : 0);
  },
  refreshPage() {
    const lc = window.location.href.split('#')[0];
    window.location.href = lc;
  },
  testSize(query) {
    return window.matchMedia(query).matches;
  },
  testMaxWidth(w) {
    return this.testSize(`(max-width: ${w}px)`);
  },
  camelize(str) {
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, (letter, index) => {
      const retVal = index === 0 ? letter.toLowerCase() : letter.toUpperCase();
      return retVal;
    }).replace(/\s+/g, '');
  },
  toCamelCase(str) {
    str = str || '';
    str = str.replace(/-/g, ' ').replace(/_/g, ' ');
    return str.replace(/(?:^\w|[A-Z]|\b\w)/g, (letter) => {
      const retVal = letter.toUpperCase();
      return retVal;
    }).replace(/\s+/g, '');
  },
  kebabToCamel(val) {
    val = val || '';
    val = val.replace(/-/g, ' ').replace(/_/g, ' ');
    return this.camelize(val);
  },
  toKebabCase(val) {
    return $.trim(val.replace(/([A-Z])/g, ' $1').toLowerCase()).replace(/\s+/g, '-');
  },
  testMinWidth(w) {
    return this.testSize(`(min-width: ${w}px)`);
  },
  isTouchDevice() {
    return 'ontouchstart' in window // works on most browsers
      || navigator.maxTouchPoints; // works on IE10/11 and Surface
  },
  getWindowOrientation() {
    if (window.orientation && Math.abs(window.orientation) === 90) {
      return 'landscape';
    }
    return 'portrait';
  },
  isMobileDev() {
    return this.isTouchDevice() && this.testMaxWidth(764);
  },
  removeFromArray(arr, val) {
    if (arr && arr.length && arr.indexOf(val) > -1) {
      arr.splice(arr.indexOf(val), 1);
    }
    return arr;
  },
  arrayPop(arr) {
    return arr.splice(arr.length - 1, 1)[0];
  },
  cmpAsc(x, y) {
    if (x > y) { return 1; }
    if (x < y) { return -1; }
    return 0;
  },
  cmpDesc(x, y) {
    if (x > y) { return -1; }
    if (x < y) { return 1; }
    return 0;
  },
  minXy(x, y) {
    return (x < y) ? x : y;
  },
  maxXy(x, y) {
    return (x > y) ? x : y;
  },
  deepCopy(v) {
    return JSON.parse(JSON.stringify(v));
  },
  dateFromString(dt) {
    const parts = dt.split(/[^0-9]+/).map((item) => parseInt(item, 10));
    return new Date(Date.UTC(parts[0], parts[1] - 1, parts[2], 0));
  },
  convDate(dt) {
    if (typeof dt === 'string') {
      dt = appHelper.dateFromString(dt);
    }
    return dt;
  },
  dateOffset(dt, days, hours, minutes, seconds) {
    dt = dt || new Date();
    dt = appHelper.convDate(dt);
    days = days || 0;
    hours = hours || 0;
    minutes = minutes || 0;
    seconds = seconds || 0;
    return new Date(dt.getTime() + 24 * 3600 * 1000 * days + hours * 3600 * 1000 + 60 * 1000 * minutes + 1000 * seconds);
  },
  xStorageGet(storEng, key, def) {
    const v = storEng.getItem(`storage_${key}`);
    if (v === null && def !== null) {
      return def;
    }
    if (v) {
      if (v.expires) {
        if (v.expires < new Date().getTime()) {
          storEng.removeItem(`storage_${key}`);
          return def;
        }
      }
      return JSON.parse(v).value;
    }
    return null;
  },
  xStorageSet(storEng, key, val, expires = null) {
    if (expires && expires.toString().match(/^[0-9]+$/)) {
      const days = parseInt(expires.toString(), 10);
      expires = new Date();
      expires.setTime(expires.getTime() + (days * 24 * 60 * 60 * 1000));
    }
    storEng.setItem(`storage_${key}`, JSON.stringify({ value: val, expires }));
  },
  xStorageRemove(storEng, key) {
    storEng.removeItem(`storage_${key}`);
  },
  localStorage: {
    get(key, def) {
      return appHelper.xStorageGet(window.localStorage, key, def);
    },
    set(key, val, expires = null) {
      return appHelper.xStorageSet(window.localStorage, key, val, expires);
    },
    remove(key) {
      return appHelper.xStorageRemove(window.localStorage, key);
    },
  },
  sessionStorage: {
    get(key, def) {
      return appHelper.xStorageGet(window.sessionStorage, key, def);
    },
    set(key, val, expires = null) {
      return appHelper.xStorageSet(window.sessionStorage, key, val, expires);
    },
    remove(key) {
      return appHelper.xStorageRemove(window.sessionStorage, key);
    },
  },
  storageGet(key, def) {
    return appHelper.localStorage.get(key, def);
  },
  storageSet(key, val, opts) {
    let expires = null;
    if (typeof opts === 'object' && opts.validFor) {
      expires = new Date();
      expires.setTime(expires.getTime() + (opts.validFor * 24 * 60 * 60 * 1000));
    }
    return appHelper.localStorage.set(key, val, expires);
  },
  getCookie(name, defaultVal = null) {
    const match = document.cookie.match(new RegExp(`(^|;[ ]?)${name}=([^;]+)`));
    if (match) {
      return match[2];
    }
    return defaultVal;
  },
  setCookie(name, value, expires = null, path = null) {
    let cookie = `${name}=${value}`;
    if (expires) {
      const d = new Date();
      d.setTime(d.getTime() + (expires * 24 * 60 * 60 * 1000));
      cookie = `${cookie};expires=${d.toUTCString()}`;
    }
    if (path) {
      cookie = `${cookie};path=${path}`;
    } else {
      cookie = `${cookie};path=/`;
    }
    document.cookie = cookie;
  },
  delCookie(name) {
    this.setCookie(name, '', -1);
  },
  forEach(items, callback, context) {
    if (typeof items === 'object' && typeof items.length === 'undefined') {
      const arr = Object.keys(items);
      for (let x = 0; x < arr.length; x += 1) {
        let result;
        const obj = items[arr[x]];
        if (context) {
          result = callback.call(context, obj, arr[x]);
        } else {
          result = callback(obj, arr[x]);
        }
        if (result === false) {
          break;
        }
      }
    } else if (typeof items.length !== 'undefined') {
      let result;
      for (let x = 0; x < items.length; x += 1) {
        if (context) {
          result = callback.call(context, items[x], x);
        } else {
          result = callback(items[x], x);
        }
        if (result === false) {
          break;
        }
      }
    }
  },
  openModal(selector) {
    // eslint-disable-next-line
    alert('openModal Not implemented yet' + selector);
  },
  formatFileSize(fSize) {
    fSize = parseInt(fSize, 10);
    if (fSize > 1024 * 1024 * 1024) {
      return (fSize / (1024.0 * 1024.0 * 1024.0)).toFixed(1) + 'GB';
    }
    if (fSize > 1024 * 1024) {
      return (fSize / (1024.0 * 1024.0)).toFixed(1) + 'MB';
    }
    if (fSize > 1024) {
      return (fSize / (1024.0)).toFixed(1) + 'kB';
    }
    return fSize + 'B';
  },
};

class BaseHttp {
  constructor(apiRoot = '/', defaultHeaders = null) {
    this.defaultHeaders = defaultHeaders || {};
    this.apiRoot = apiRoot;
  }

  prepareDefaultHeaders() {
    const headers = this.defaultHeaders || {};
    const finalHeaders = {};
    let val = null;
    Object.keys(headers).forEach((k) => {
      if (typeof headers[k] === 'function') {
        val = headers[k]();
      } else {
        val = headers[k];
      }
      if (val) {
        finalHeaders[k] = val;
      }
    });
    return finalHeaders;
  }

  applyHeaders(p, options) {
    const p2 = $.extend(true, {}, p || {});
    options = options || {};
    if (this.defaultHeaders) {
      const hdrs = this.prepareDefaultHeaders();
      const headers = $.extend(true, {}, hdrs, p.headers || options.headers || {});
      p2.headers = headers;
    }
    return p2;
  }

  // eslint-disable-next-line no-unused-vars
  sendReq(httpParams, options) { // options
    const promiseObj = new Promise((resolve, reject) => {
      options = options || {};
      httpParams = this.applyHeaders(httpParams, options);
      axios(httpParams)
        .then((httpResponse) => {
          const { data } = httpResponse;
          if (data.status === 'rejected' || data.status === 'err_redirect') {
            if (data.url) {
              window.location.href = data.url;
              return;
            }
          }
          resolve(data);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
    return promiseObj;
  }

  // eslint-disable-next-line class-methods-use-this
  checkParamsOpts(params, options) {
    let result = {
      params,
      options,
    };
    if (params && typeof params === 'object' && !options && (params.statusEvent || params.apiRoot || (params.headers && typeof params.headers === 'object'))) {
      result = {
        params: null,
        options: params,
      };
    }
    return result;
  }

  prepareUrl(url, params, options) {
    options = options || {};
    let theApiRoot = options.apiRoot || this.apiRoot;
    if (url && url[0] === '/') {
      theApiRoot = '';
    }
    if (params && typeof params === 'object') {
      const q = [];

      appHelper.forEach(params, (val, key) => {
        q.push(`${key}=${encodeURIComponent(val)}`);
      });
      params = q.join('&');
    }
    const getParams = (params) ? `?${params}` : '';
    let finalUrl = `${theApiRoot}${url.replace('.', '/')}`;
    if (finalUrl.indexOf('?') === -1) {
      finalUrl += '/';
    }
    finalUrl += `${getParams}`;
    return finalUrl.replace('//', '/');
  }

  get(url, params, options) {
    const parOpt = this.checkParamsOpts(params, options);
    const finalUrl = this.prepareUrl(url, parOpt.params, parOpt.options);
    const p = {
      method: 'get',
      url: finalUrl,
    };
    return this.sendReq(p, parOpt.options);
  }

  post(url, data, params, options) {
    const parOpt = this.checkParamsOpts(params, options);
    const finalUrl = this.prepareUrl(url, parOpt.params, parOpt.options);
    const p = {
      method: 'post',
      url: finalUrl,
      data,
    };
    return this.sendReq(p, parOpt.options);
  }

  put(url, data, params, options) {
    const parOpt = this.checkParamsOpts(params, options);
    const finalUrl = this.prepareUrl(url, parOpt.params, parOpt.options);
    const p = {
      method: 'put',
      url: finalUrl,
      data,
    };
    return this.sendReq(p, parOpt.options);
  }

  delete(url, options) {
    const finalUrl = this.prepareUrl(url, null, options);
    const p = {
      method: 'delete',
      url: finalUrl,
    };
    return this.sendReq(p, options);
  }

  head(url, params, options) {
    const parOpt = this.checkParamsOpts(params, options);
    const finalUrl = this.prepareUrl(url, parOpt.params, parOpt.options);
    const p = {
      method: 'head',
      url: finalUrl,
    };
    return this.sendReq(p, parOpt.options);
  }

  options(url, params, options) {
    const parOpt = this.checkParamsOpts(params, options);
    const finalUrl = this.prepareUrl(url, parOpt.params, parOpt.options);
    const p = {
      method: 'options',
      url: finalUrl,
    };
    return this.sendReq(p, parOpt.options);
  }

  postForm(url, formEl, data) {
    if (!data) {
      data = $(formEl).serialize();
    }
    const finalUrl = this.prepareUrl(url);
    const httpParams = {
      method: 'POST',
      url: finalUrl,
      data,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };
    return this.sendReq(httpParams);
  }
}

class HttpApi extends BaseHttp {
  constructor() {
    super('/', {});
  }
}

const Server = new HttpApi();

export {
  getModel,
  Server,
  appHelper,
  goToPage,
  Deferred,
};
