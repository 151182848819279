import * as PfBase from '&/utils/base';
import * as helperMod from '&/utils/helpers';
import jquery from 'jquery';
import signIn from '&/components/signIn';
import signUp from '&/components/signup';
import userDetails from '&/components/userDetails';
import changePassword from '&/components/changePassword';
import notifyList from '&/components/notifyList';

const $ = jquery;
const helper = helperMod.appHelper;
const { Deferred } = helperMod;
const { $rootScope } = PfBase;
const { Server } = helperMod;

// helper.consoleLog

const baseCtrlMixIn = {
  components: {
    signIn,
    signUp,
    userDetails,
    notifyList,
    changePassword,
  },
  data() {
    return {
      F: null,
      isTouchMobile: false,
      isAuthenticated: false,
      userFullyConfigured: false,
      notifications: false,
      unread_count: 0,
      user_playlists: {},
      showAddPlaylist: false,
      showMobileSearch: false,
      buttonDisableStates: {},
      searchString: '',
      playlist: {
        playlist_name: '',
        name: '',
        playlist_type: 1,
        is_checked: true,
      },
    };
  },
  created() {
    this.getUserPlaylist();
    this.F = this.$options.filters;
    $(document).scroll(() => {
      const curentTop = $(document).scrollTop();
      $rootScope.$emit('pageScrolled', curentTop);
    }).scroll();
    $rootScope.$on('showUserNotificationBox', () => {
      this.markNotificationsRead();
    });
    const messages = helper.sessionStorage.get('messages') || [];
    helper.forEach(messages, (message) => {
      if (message.eventName) {
        this.$nextTick(() => {
          $rootScope.$emit(message.eventName, message);
        });
      } else {
        this.makeToast(message.message, message.type || 'info');
      }
    });
    helper.sessionStorage.remove('messages');
    // this.cyclicalNotif();
  },
  methods: {
    commonAddMessage(message) {
      const messages = helper.sessionStorage.get('messages') || [];
      messages.push(message);
      helper.sessionStorage.set('messages', messages);
    },
    addRefreshNotification(message, msgType) {
      const msg = { type: msgType || 'info', message };
      this.commonAddMessage(msg);
    },
    addRefreshEvent(eventName, data) {
      const msg = { eventName, data };
      this.commonAddMessage(msg);
    },
    removeRefreshEvent(eventName) {
      let messages = helper.sessionStorage.get('messages') || [];
      messages = messages.filter((msg) => msg.eventName !== eventName);
      helper.sessionStorage.set('messages', messages);
    },
    isEmpty(val) {
      return !$.trim(val || '');
    },
    makeToast(message, variant) {
      this.$bvToast.toast(message, {
        title: '',
        variant,
        solid: true,
      });
    },
    confirm(msg) {
      return this.$bvModal.msgBoxConfirm(msg);
    },
    alert(msg) {
      return this.$bvModal.msgBoxOk(msg);
    },
    showModal(key, evt) {
      if (evt === true) {
        evt = { stacking: false };
      }
      if (key[0] === '#') {
        this.$root.$bvModal.show(key.substr(1));
      } else {
        $rootScope.$emit(`${key}_open`, evt);
      }
    },
    showModalPromise(key, evt) {
      const promiseObj = new Deferred();
      evt = evt || {};
      evt.notifyWhenClosed = `k_${new Date().getTime()}`;
      $rootScope.$once(`${key}_closed_${evt.notifyWhenClosed}`, (evt2) => {
        promiseObj.resolve(evt2);
      });
      this.showModal(key, evt);
      return promiseObj.promise;
    },
    toggleMobileSearch() {
      this.showMobileSearch = !this.showMobileSearch;
    },
    submitMyForm(evt) {
      $(evt.target).closest('form').submit();
    },
    getUserNotifications() {
      const url2 = '/api/notifications/';
      Server.get(url2).then((data) => {
        this.notifications = data.notifications || [];
        this.unread_count = data.unread_count;

        helper.forEach(this.notifications, (obj) => {
          if (obj.nf_type === 'show_notify') {
            let comment = obj.user;
            if (obj.user !== 'GSA System') comment += ' replied:';
            comment += ' ' + obj.comment;
            this.notifSuccess(comment);
          }
        });
        const scroll = $(window).scrollTop();
        setTimeout(() => {
          $(window).scrollTop(scroll);
        }, 10);
      });
    },
    cyclicalNotif() {
      setTimeout(() => {
        this.getUserNotifications();
        this.cyclicalNotif();
      }, 10000);
    },
    openNotifyMob() {
      this.markNotificationsRead();
      this.showModal('notifylist');
    },
    markNotificationsRead() {
      const url2 = '/api/notifications/';
      const data = { action: 'read' };
      Server.post(url2, data).then((respData) => {
        if (respData.status === 'OK' && respData.action === 'read') {
          this.unread_count = 0;
        }
      });
    },
    evaluate(expr, param) {
      if (param) {
        param = JSON.stringify(param);
      }
      let tmpx = false;
      if (!tmpx) {
        tmpx = true;
      }
      let addThis = 'this.';
      if (expr && expr.indexOf('@') === 0) {
        addThis = '';
        expr = expr.substr(1);
      } else if (expr && expr.indexOf('=') === 0) {
        addThis = 'tmpx';
      }
      let extraAssignment = '';
      if (param) {
        extraAssignment = `var param=${param};`;
      }
      if (tmpx) {
        // eslint-disable-next-line
        const result = eval(`${extraAssignment}${addThis}${expr}`);
        return result;
      }
      return null;
    },
    notification(message, variant, title, options) {
      options = options || {};
      let opts = {
        variant,
        solid: true,
      };
      if (title) {
        opts.title = title;
      }
      opts = $.extend(opts, options);
      this.$bvToast.toast(message, opts);
    },
    notifInfo(message, title, options) {
      this.notification(message, 'default', title, options);
    },
    notifError(message, title, options) {
      this.notification(message, 'danger', title, options);
    },
    notifSuccess(message, title, options) {
      this.notification(message, 'success', title, options);
    },
    apply() {
      this.$forceUpdate();
    },
    getPlaylistUrl() {
      let url = '/api/playlist/';
      if (typeof window.page_id !== 'undefined') {
        this.playlist.page_id = window.page_id;
        url += '?page_id=' + window.page_id;
      }
      return url;
    },
    resetAddPlaylistPopup() {
      this.showAddPlaylist = false;
      this.playlist.playlist_name = '';
      this.playlist.name = '';
    },
    getUserPlaylist() {
      this.resetAddPlaylistPopup();
      Server.get(this.getPlaylistUrl()).then((data) => {
        this.user_playlists = data;
      });
    },
    goToUrl(url, delay) {
      if (delay) {
        setTimeout(() => {
          window.location.href = url;
        }, delay);
      } else {
        window.location.href = url;
      }
    },
    tostring(v) {
      return (v || '').toString();
    },
    closeDropdown(selector) {
      $(selector).dropdown('toggle');
    },
    condSubmitForm(evt, frm) {
      // used as helper for submiting forms
      if (!evt) {
        return null;
      }
      if (evt.currentTarget) {
        if ($(evt.currentTarget).hasClass('disabled')) {
          return null;
        }
      }
      return $('form[name="' + frm + '"]').submit();
    },
    testSize(query) {
      // it is function that can be used inside angular controller (and can be used in templates) than calls global function to test the browser size
      return helper.testSize(query);
    },
    testMaxWidth(w) {
      // it is function that can be used inside angular controller (and can be used in templates) than calls global function to test the browser size
      return helper.testMaxWidth(w);
    },
    testMinWidth(w) {
      // it is function that can be used inside angular controller (and can be used in templates) than calls global function to test the browser size
      return helper.testMinWidth(w);
    },
    reloadPage() {
      helper.refreshPage();
    },
    isMobileDev() {
      // is used to check if the client is mobile dev
      return helper.isMobileDev();
    },
    debugLog(...vals) {
      // for debugging purpose
      helper.consoleLog('debug:', ...vals);
    },
    conditionalSubmitAction(evt, action, ...args) {
      if (evt && evt.currentTarget) {
        if ($(evt.currentTarget).hasClass('disabled')) {
          return null;
        }
      }
      return this[action](...args);
    },
    emitEvt(event, ...args) {
      $rootScope.$emit(event, ...args);
    },
    setAuth(isAuth, fullyConfigured) {
      this.isAuthenticated = isAuth;
      this.userFullyConfigured = fullyConfigured;
    },
    getIsAuthenticated() {
      return this.isAuthenticated;
    },
    openAuthBox(evt, urlRedirect) {
      this.showModal('signin', { redirectUrl: urlRedirect });
      evt.preventDefault();
    },
    openChangePassword() {
      this.showModal('changepassword');
    },
    goOnce(evt, url, buttonStateName) {
      if ($(evt.target).data('alreadyClicked')) {
        return;
      }
      $(evt.target).data('alreadyClicked', true);
      if (buttonStateName) {
        this.$set(this.buttonDisableStates, buttonStateName, true);
      }
      this.$nextTick(() => {
        window.location.href = url;
      });
      evt.preventDefault();
    },
    allowPostContent() {
      const promiseObj = new Deferred();
      if (this.userFullyConfigured) {
        setTimeout(() => {
          promiseObj.resolve();
        }, 0);
      } else {
        this.showModal('updateaccount');
        $rootScope.$on('user-details-modal-close', (value) => {
          if (value) {
            this.setAuth(true, true);
            promiseObj.resolve();
          } else {
            promiseObj.reject({
              reason: 'First and last name required.',
            });
          }
          $rootScope.$off('user-details-modal-close');
        });
      }
      return promiseObj.promise;
    },
    getReferralLink(baseUrl, params) {
      if (baseUrl === true) {
        baseUrl = window.location.href;
      }
      // let url = window.location.href;
      if (baseUrl) {
        [baseUrl] = baseUrl.split('#');
        if (params) {
          const separator = baseUrl.indexOf('?') === -1 ? '?' : '&';
          const extraGetParams = [];
          helper.forEach(params, (v, k) => {
            extraGetParams.push(`${k}=${encodeURIComponent(v)}`);
          });
          baseUrl += separator + extraGetParams.join('&');
        }
        this.alert(baseUrl);
      }
    },
  },
};

export { baseCtrlMixIn as default };
