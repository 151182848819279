import * as PfBase from '&/utils/base';
import * as directives from '&/directives/DirectiveBase';

const { formEnterPress, enterPress } = directives;

const { $rootScope } = PfBase;

export default {
  directives: { formEnterPress, enterPress },
  props: [],
  watch: {
  },
  data() {
    return {
      stopStacking: false,
      notifyWhenClosed: false,
    };
  },
  computed: {
    myId() {
      return `bv-modal-${this.popupKey}`;
    },
  },
  created() {
    this.onCreated();
    $rootScope.$on(`${this.popupKey}_open`, (evt) => {
      this.notifyWhenClosed = (evt || {}).notifyWhenClosed || false;
      const doShow = () => {
        if (evt && typeof evt.stacking !== 'undefined') {
          this.stopStacking = !evt.stacking;
          // no-stacking
        }
        this.$root.$bvModal.show(this.myId);
        this.onShow(evt);
      };
      if (this.beforeShow) {
        this.beforeShow().then(() => {
          doShow();
        });
      } else {
        doShow();
      }
    });
    $rootScope.$on(`${this.popupKey}_close`, () => {
      this.closeModal();
      (this.onClosed || (() => {}))();
    });
    if (this.onHide || this.notifyWhenClosed !== false) {
      this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
        if (modalId === this.myId) {
          if (this.onHide) {
            this.onHide(bvEvent.trigger, bvEvent, modalId);
          }
          if (this.notifyWhenClosed) {
            $rootScope.$emit(`${this.popupKey}_closed_${this.notifyWhenClosed}`, {
              type: bvEvent.trigger,
              event: bvEvent,
              modalId,
            });
          }
        }
      });
    }
    if (this.onHidden) {
      this.$root.$on('bv::modal::hidden', (bvEvent, modalId) => {
        if (modalId === this.myId) {
          this.onHidden(bvEvent, modalId);
        }
      });
    }
  },
  methods: {
    showModal(key, closeme) {
      if (closeme) {
        this.closeModal();
      }
      this.$root.showModal(key);
    },
    closeModal() {
      this.$root.$bvModal.hide(`bv-modal-${this.popupKey}`);
    },
    onCreated() {
    },
    onShow() {
    },
  },
};
