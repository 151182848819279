<template>
  <div class="coach-wrapper" :data-pk="cid" :data-cid="coachId">
    <b-modal :id="coachId">
      <div class="container coach-popup">
        <div class="row">
          <div class="col-12 col md-3">
            <div class="face-wrapper">
              <div class="face-bg" :style="{'background-image': 'url(' + image + ')'}"></div>
            </div>

          </div>
          <div class="col-12 col md-9">
            <div class="card mb-3">
              <div class="card-body">
                <h5 class="card-title">
                  <span class="page-title h3-xs h6-md" v-text="name"></span>
                </h5>
                <div class="card-text">
                  <p class="" v-if="certificationlevel">GSA Certifications - <span v-text="certificationlevel"></span></p>
                  <p class="" v-if="countryclubaffiliations" v-text="countryclubaffiliations"></p>
                  <p class="" v-if="city" v-text="city"></p>
                  <p class="" v-if="email"><a class="" :href="'mailto:' + email" v-text="email"></a></p>
                  <p v-if="url"><a class="" :href="url" target="_blank" v-text="url"></a></p>
                  <p class="" v-if="phone" v-text="phone"></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import baseModalComponent from '&/utils/baseModalComponent.js';
import * as helperMod from '&/utils/helpers';

const hlp = helperMod.appHelper;
const $ = require('jquery');

(() => {})(hlp.consoleLog, $);
export default {
  name: 'coach',
  mixins: [baseModalComponent],
  props: ['cid', 'name', 'countryclubaffiliations', 'city', 'email', 'phone', 'image', 'url', 'certificationlevel'],
  directives: {},
  data() {
    return {
      popupKey: '',
    };
  },
  computed: {
    coachId() {
      return `bv-modal-coach-${this.cid}`;
    },
  },
  methods: {
    onCreated() {
      this.popupKey = `coach-${this.cid}`;
    },
  },
};
</script>
