import baseModalComponent from '&/utils/baseModalComponent.js';
import * as helpers from '&/utils/helpers';
import jquery from 'jquery';
import { BIconMap } from 'bootstrap-vue';

const hlp = helpers.appHelper;
const $ = jquery;

(() => {})(hlp.consoleLog, $, hlp);

export default {
  name: 'notifyList',
  template: '#notify-list-template',
  mixins: [baseModalComponent],
  props: ['notifications'],
  directives: {},
  components: { BIconMap },
  data() {
    return {
      popupKey: 'notifylist',
    };
  },
};
