import jquery from 'jquery';

const $ = jquery;

$(document).ready(() => {
  // Detect ios 11_x_x affected
  // NEED TO BE UPDATED if new versions are affected
  const ua = navigator.userAgent;
  const iOS = /iPad|iPhone|iPod/.test(ua);
  const iOS11 = /OS 11_0_1|OS 11_0_2|OS 11_0_3|OS 11_1|OS 11_1_1|OS 11_1_2|OS 11_2|OS 11_2_1/.test(ua);

  // ios 11 bug caret position
  if (iOS && iOS11) {
    // Add CSS class to body
    $('body').addClass('iosBugFixCaret');
  }

  $('#notification_icon2.menu-text').on('click', () => {
    $('#widemenu').hide();
  });
});

export default {
};
