<template>
    <div class="mb-2">
        <!--h4 class="pb-2">GET 15% OFF YOUR MEMBERSHIP - USE CODE <span class="text-green"> GSA15</span></h4-->
        <h4 class="text-grey mb-1">TIME LEFT ON FREE TRIAL</h4>
        <div class="header1 text-red">{{ hours | twoDigits }}:{{ minutes | twoDigits }}:{{ seconds | twoDigits }}</div>
    </div>
</template>

<script>
let interval = null;
export default {
    name: 'vuejsCountDown',
    props: {
        deadline: {
            type: String
        },
        end: {
            type: String
        },
        stop: {
            type: Boolean
        },
        code: {
            type: String,
            default: null,
        },
    },
    data() {
        return {
            now: Math.trunc((new Date(new Date().toLocaleString("en-US", {timeZone: "America/Los_Angeles"}))).getTime() / 1000),
            date: null,
            diff: 0
        }
    },
    created() {
        if (!this.deadline && !this.end) {
            throw new Error("Missing props 'deadline' or 'end'");
        }
        let endTime = this.deadline ? this.deadline : this.end;
        this.date = Math.trunc(Date.parse(endTime.replace(/-/g, "/")) / 1000);
        if (!this.date) {
            throw new Error("Invalid props value, correct the 'deadline' or 'end'");
        }
        interval = setInterval(() => {
            this.now = Math.trunc((new Date(new Date().toLocaleString("en-US", {timeZone: "America/Los_Angeles"}))).getTime() / 1000);
        }, 1000);
    },
    computed: {
        seconds() {
            return Math.trunc(this.diff) % 60
        },
        minutes() {
            return Math.trunc(this.diff / 60) % 60
        },
        hours() {
            return Math.trunc(this.diff / 60 / 60)
        },
    },
    watch: {
        now(value) {
            this.diff = this.date - this.now;
            if(this.diff <= 0 || this.stop){
                this.diff = 0;
                // Remove interval
                clearInterval(interval);

                // Destroy current element
                this.$destroy();

                this.$el.parentNode.removeChild(this.$el);
            }
        }
    },
    filters: {
        twoDigits(value) {
            if ( value.toString().length <= 1 ) {
                return '0'+value.toString()
            }
            return value.toString()
        }
    },
    destroyed() {
        clearInterval(interval);
    }
}
</script>
<style>
</style>