// import * as helpers from '&/utils/helpers';
// import * as PfBase from '&/utils/base';
import * as helpers from '&/utils/helpers';
import baseCtrlMixIn from '&/utils/baseController';

const hlp = helpers.appHelper;

const ctrlMulliganSignup = {
  name: 'ctrlMulliganSignup',
  el: '.ctrl-mulligan-signup-controller',
  components: {
  },
  mixins: [baseCtrlMixIn],
  data() {
    return {
      successUrl: '',
      signUpForm: {
        first_name: '',
        last_name: '',
        email: '',
        password1: '',
      },
    };
  },
  created() {
  },
  methods: {
    signUpInvalid() {
      let isValid = true;
      const names = ['first_name', 'last_name', 'email', 'password1'];
      hlp.forEach(names, (name) => {
        isValid = isValid && this.signUpForm[name];
      });
      return !isValid;
    },
    submitSignupForm() {
    },
    setSuccessUrl(url) {
      this.successUrl = url;
    },
  },
};
export default ctrlMulliganSignup;
