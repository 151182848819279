import * as helpers from '&/utils/helpers';
import baseCtrlMixIn from '&/utils/baseController';
import jquery from 'jquery';

const $ = jquery;

const hlp = helpers.appHelper;
const { Server, Deferred } = helpers;

(() => {})(hlp.consoleLog, $);

const CtrlSubmission = {
  name: 'CtrlSubmission',
  el: '.ctrl-submission-controller',
  components: {
  },
  mixins: [baseCtrlMixIn],
  data() {
    return {
      file1: false,
      file2: false,
      inputNotes: '',
      disableSubmit: true,
      progress: {
        p0: {
          val: 0,
          status: false,
          message: { type: '', msg: '' },
        },
        p1: {
          val: 0,
          status: false,
          message: { type: '', msg: '' },
        },
      },
    };
  },
  created() {
  },
  methods: {
    initS3Forms(getUploadParamsUrl, formEl) {
      const fileEl = formEl.find('input[name=file]')[0];
      const promiseObj = new Deferred();
      if (!fileEl.files || !fileEl.files[0]) {
        setTimeout(() => {
          promiseObj.resolve(false);
        });
      } else {
        Server.get(getUploadParamsUrl, { filename: fileEl.files[0].name }).then((params) => {
          formEl.find('input[name=key]').val(params.key);
          formEl.find('input[name=policy]').val(params.policy);
          formEl.find('input[name=signature]').val(params.signature);
          promiseObj.resolve(true);
        });
      }
      return promiseObj.promise;
    },
    getProgressValue(nr) {
      const { val } = this.progress['p' + nr];
      return { width: `${val}%` };
    },
    updateFileVal(evt, el) {
      const fl = evt.target.files[0];
      if (fl) {
        this[el] = fl.name;
      }
    },
    submitVideos(selector, getUploadParamsUrl, uploadSuccessUrl) {
      let expectedResponses = 0;
      let formsLeft = $(selector).length;
      const urlsArr = [];
      let finished = 0;
      $(selector).each((idx, element) => {
        const el = $(element);
        this.initS3Forms(getUploadParamsUrl, el).then((result) => {
          formsLeft -= 1;
          if (result) {
            expectedResponses += 1;
            const formNr = parseInt(el.data('form-nr'), 10);
            const formData = new FormData(element);
            const request = new XMLHttpRequest();
            const $this = this;
            request.upload.onprogress = (ev) => {
              $this.progress['p' + (formNr - 1)].val = (100 * ev.loaded) / ev.total;
              $this.$forceUpdate();
            };
            request.addEventListener('error', () => {
              $this.progress['p' + (formNr - 1)].status = 'failed';
              $this.progress['p' + (formNr - 1)].message = {
                type: 'error',
                msg: 'Error: File is larger than 100MB. Please try again.',
              };
            }, false);
            request.addEventListener('load', () => {
              finished += 1;
              urlsArr.push($(request.response).find('Location').text());
              if (!formsLeft && finished === expectedResponses) {
                const notes = $('#id_inputNotes').val();
                Server.post(uploadSuccessUrl, { urls: urlsArr, notes }).then((redirect) => {
                  window.location.href = redirect.url;
                });
              }
            }, false);
            request.open('POST', el.attr('action'));
            request.send(formData);
          }
        });
      });
    },
  },
};
export default CtrlSubmission;
