import Vue from 'vue';

const $ = require('jquery');

const $rootScope = new Vue();

class PfVue extends Vue {
  /* it is base class for the project
      we need to replace starting and ending tags that collide with djanogo syntax {{ }}
      with {! !} it adds some helpers that can assign values to element models by
      PfVue.updateModel(vnode, value) it defines xcomponent that should be used instead of
      Vue.component to let us use {! !} format in components

      We can register vue instances by PfVue.register method by passing its name and the
      object that we pass normally for Vue instance. We don't have to worry if the other
      parts of js is started and the DOM is ready. After running the instance will be
      available in PfVue.instances['the_name_passed_as_first_param']
  */
  constructor(...vals) {
    let param;
    let name = '';
    if (typeof vals[0] === 'string') {
      [name, param] = vals;
    } else {
      [param] = vals;
    }
    param.delimiters = ['{!', '!}'];
    const inst = super(param);
    if (name) {
      PfVue.instances[name] = inst;
    }
  }

  static getModel(vnode) {
    let models;
    let directives;
    if (vnode.data && vnode.data.directives) {
      ({ directives } = vnode.data);
    } else if (vnode.directives) {
      ({ directives } = vnode);
    }
    if (directives) {
      models = directives.filter((v) => v.name === 'model');
      if (models.length) {
        return models[0].expression;
      }
    }
    return null;
  }

  static updateModel(...params) {
    /*
      We can pass 2 parameters:
          it can be vnode, value
      Or we can pass 3 parameters:
          instance, model, value
          where model can be either string or vnode or vnode.data
          and instance can be either vnode or vnode.context
    */
    let model;
    let instance;
    let value;
    if (params.length === 2) {
      [model, value] = params;
      instance = model;
    } else if (params.length === 3) {
      [instance, model, value] = params;
    }
    if (instance.context) {
      instance = instance.context;
    }
    if (typeof model !== 'string') {
      model = PfVue.getModel(model);
      if (!model) {
        return;
      }
    }

    PfVue.sAssign(instance, model, value, true);
  }

  static register(...params) {
    PfVue.instanceParams.push(params);
  }

  static xcomponent(name, params) {
    params.delimiters = ['{!', '!}'];
    return PfVue.component(name, params);
  }

  static run() {
    PfVue.instanceParams.forEach((item) => {
      let paramSet;
      let name = '';
      if (item.length > 1 && typeof item[0] === 'string') {
        [name, paramSet] = item;
      } else {
        paramSet = item;
      }
      if (!paramSet.el || $(paramSet.el).length) {
        // eslint-disable-next-line no-new
        new PfVue(name, paramSet);
      }
    });
  }

  static parse(instance, key) {
    const parts = key.split('.');
    if (instance.context) {
      instance = instance.context;
    }
    let currVal = instance;
    let part;
    for (let x = 0; x < parts.length; x += 1) {
      part = parts[x];
      currVal = currVal[part];
    }
    return currVal;
  }

  static sAssign(instance, vr, value, force = false) {
    const parts = vr.split('.');
    if (instance.context) {
      instance = instance.context;
    }
    let currVal = instance;
    let part;
    const lastPart = parts[parts.length - 1];
    for (let x = 0; x < parts.length - 1; x += 1) {
      part = parts[x];
      if (force && currVal[part] === undefined) {
        currVal[part] = {};
      }
      currVal = currVal[part];
    }

    currVal[lastPart] = value;
  }

  assign(vr, value, force = false) {
    PfVue.sAssign(this, vr, value, force);
  }

  static getDirective(vnode, dirName) {
    const dirs = vnode.data.directives.filter((v) => v.name === dirName);
    if (dirs.length) {
      return dirs[0];
    }
    return null;
  }

  static getDirectiveMethods(vnode, dirName) {
    const dir = PfVue.getDirective(vnode, dirName);
    if (dir) {
      return dir.def.methods || dir.def;
    }
    return null;
  }
}

PfVue.instanceParams = [];
PfVue.instances = {};

function parse(key) {
  const wrapper = (instance) => {
    let reverse = false;
    if (key && key[0] === '!') {
      reverse = true;
      key = key.substr(1);
    }
    const result2 = PfVue.parse(instance, key);
    if (reverse) {
      return !result2;
    }
    return result2;
  };
  return wrapper;
}

export {
  PfVue,
  $rootScope,
  parse,
};
