import baseCtrlMixIn from '&/utils/baseController';

const CtrlAuth = {
  name: 'CtrlAuth',
  el: '.ctrl-auth-controller',
  components: {
  },
  mixins: [baseCtrlMixIn],
  data() {
    return {
      minLength: 8,
      password1: '',
      password2: '',
      password1Blurred: false,
      password2Blurred: false,
      attemptToSend: false,
    };
  },
  computed: {
    canShowError() {
      let canShow = (this.password1Blurred && this.password2Blurred) || this.attemptToSend;
      if (!canShow && this.password2 && this.password1 && this.password1.length < this.minLength) {
        canShow = true;
      }
      return canShow;
    },
    passwordError() {
      return this.password1Blured && (!this.password1 || this.password1.length < this.minLength);
    },
    formValid() {
      return this.password1 && this.password1.length >= this.minLength && this.password2 === this.password1;
    },
    formInvalid() {
      if (!this.password1) {
        return 'empty';
      }
      if (this.password1.length < this.minLength) {
        return 'min_length';
      }
      if (this.password2 !== this.password1) {
        return 'not_match';
      }
      return false;
    },
  },
  created() {
  },
  methods: {
    setAttempt() {
      this.attemptToSend = true;
    },
  },
};
export default CtrlAuth;
