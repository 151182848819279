<template>
  <div class="coach-google-map-wrapper" :style="getWrapperStyle()">
    <div id="gmap-coach" style="height:100%;"></div>
  </div>
</template>
<script>
import * as helperMod from '&/utils/helpers';
import * as directives from '&/directives/DirectiveBase';
import * as PfBase from '&/utils/base';

const { $rootScope } = PfBase;

const hlp = helperMod.appHelper;
const $ = require('jquery');

const { Server } = helperMod;

(() => {})(hlp.consoleLog, $, Server, $rootScope, directives);
export default {
  name: 'googleMapsCoaches',
  mixins: [],
  props: ['apikey', 'extractHeight', 'addresses', 'setcoord'],
  directives: {},
  created() {
    hlp.waitForElement('#gmap-coach').then(() => {
      hlp.waitForIt(() => window.google && window.google.maps).then(() => {
        this.geocoder = new window.google.maps.Geocoder();
        this.map = new window.google.maps.Map(document.getElementById('gmap-coach'), {
          center: { lat: 41.72583, lng: -99.90725 }, // 41.72583, -99.90725
          zoom: 2,
        });
        hlp.forEach(this.addresses, (addr, pk) => {
          if (addr.lat || addr.lng) {
            this.setMarker(addr, pk, { lat: addr.lat, lng: addr.lng });
          } else {
            this.codeAddress(addr, pk);
          }
        });
      });
    });
  },
  data() {
    return {
      map: false,
      geocoder: false,
      markers: [],
    };
  },
  methods: {
    getWrapperStyle() {
      const eh = this.extractHeight || 0;
      return { height: `calc(100vh - ${eh}px)` };
    },
    codeAddress(coach, pk) {
      const { address } = coach;
      if (!address) {
        return;
      }
      this.geocoder.geocode({ address }, (results, status) => {
        if (status === window.google.maps.GeocoderStatus.OK) {
          this.setMarker(coach, pk, results[0].geometry.location);
          const lat = results[0].geometry.location.lat();
          const lng = results[0].geometry.location.lng();
          Server.put(`/api/coach-coord/${pk}/`, { lat, lng }).then(() => {}, () => {});
        }
      });
    },
    setMarker(coach, pk, location) {
      const marker = new window.google.maps.Marker( {
        map: this.map,
        position: location,
        title: coach.title,
      });
      marker.addListener('click', () => {
        this.$emit('markerclicked', { pk, coach });
      });
      this.markers.push(marker);
    },
  },
};
</script>
