<template>
  <div>
    <b-modal :id="myId" :no-stacking="stopStacking" :hide-footer="true">
      <h3>Change password</h3>
      <div class="form-container">
        <form v-form-enter-press form-enter-press="#user-details-modal-btn-submit">
          <fieldset class="form-group">
            <!-- Text input-->
            <div class="control-group">
              <div class="bg-danger p-3 mb-2 text-white" v-if="errors.current_password">
                <ul class="list-unstyled mb-0">
                  <li v-for="err in errors.current_password" v-text="err"></li>
                </ul>
              </div>

              <div class="controls">
                <input v-enter-press enter-press="#change-pwd-modal-btn-submit" type="password" class="form-control"
                       maxlength="30" name="current_password" placeholder="Current password" required
                       v-model="data.current_password">
              </div>
            </div>

            <!-- Text input-->
            <div class="control-group mt-3">
              <div class="bg-danger p-3 mb-2 text-white" v-if="errors.new_password">
                <ul class="list-unstyled mb-0">
                  <li v-for="err in errors.new_password" v-text="err"></li>
                </ul>
              </div>
              <div class="controls">
                <input v-enter-press enter-press="#change-pwd-modal-btn-submit" type="password" class="form-control"
                       maxlength="30" name="new_password" placeholder="New password" required
                       v-model="data.new_password">
              </div>
            </div>

            <!-- Text input-->
            <div class="control-group mt-3">
              <div class="bg-danger p-3 mb-2 text-white" v-if="data.new_password && confirmPassword && data.new_password != confirmPassword">
                Passwords don't match.
              </div>
              <div class="controls">
                <input v-enter-press enter-press="#change-pwd-modal-btn-submit" type="password" class="form-control"
                       maxlength="30" name="confirmPassword" placeholder="Confirm password" required
                       v-model="confirmPassword">
              </div>
            </div>
            <div class="control-group mt-3">
              <div class="controls">
                <a class="btn btn-primary" tabindex="0"
                   id="change-pwd-modal-btn-submit" :class="{disabled: !formValid || changePasswordInProgress}"
                   @click="doChangePassword($event)">Change</a>
              </div>
            </div>
          </fieldset>
        </form>
      </div>
    </b-modal>
  </div>
</template>
<script>
import baseModalComponent from '&/utils/baseModalComponent.js';
import * as helperMod from '&/utils/helpers';
import * as directives from '&/directives/DirectiveBase';
import * as PfBase from '&/utils/base';

const { $rootScope } = PfBase;
const { enterPress } = directives;

const hlp = helperMod.appHelper;
const $ = require('jquery');

const { Server } = helperMod;

(() => {})(hlp.consoleLog, $);
export default {
  name: 'changePassword',
  mixins: [baseModalComponent],
  props: [],
  directives: { enterPress },
  data() {
    return {
      popupKey: 'changepassword',
      changePasswordInProgress: false,
      confirmPassword: '',
      data: {
        current_password: '',
        new_password: '',
      },
      errors: {},
    };
  },
  computed: {
    formValid() {
      return this.data.current_password && this.data.new_password && this.data.new_password.length >= 8 && this.confirmPassword === this.data.new_password;
    },
  },
  methods: {
    onHidden() {
      $rootScope.$emit('change-password-modal-close');
    },
    doChangePassword() {
      if (this.changePasswordInProgress) {
        return;
      }
      this.changePasswordInProgress = true;
      if (this.formValid) {
        Server.post('/api/change-pwd/', this.data).then((data) => {
          this.changePasswordInProgress = false;
          if (data.status === 'OK') {
            this.errors = {};
            this.data.current_password = '';
            this.data.new_password = '';
            this.closeModal();
            const messages = hlp.sessionStorage.get('messages') || [];
            messages.push({
              type: 'success',
              message: 'You password has been changed.',
              // eventName: 'globalMessage',
              // event: 'settingsChanged',
            });
            hlp.sessionStorage.set('messages', messages);
            hlp.refreshPage();
          } else if (data.status === 'failed') {
            this.errors = data.errors;
          }
        });
      }
    },
  },
};
</script>
