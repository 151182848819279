<template>
  <div>
    <b-modal :id="myId" :no-stacking="stopStacking" hide-footer>
      <div class="form-container">
        <div class="error message text-center" v-if="showSocialError" v-text="showSocialError"></div>
        <div class="text-center mb-3">
          <a class="btn btn-outline-dark btn-block" :href="urlgoogle">
            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M17.64 9.20443C17.64 8.56625 17.5827 7.95262 17.4764 7.36353H9V10.8449H13.8436C13.635 11.9699 13.0009 12.9231 12.0477 13.5613V15.8194H14.9564C16.6582 14.2526 17.64 11.9453 17.64 9.20443Z" fill="#4285F4"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M8.99976 18C11.4298 18 13.467 17.1941 14.9561 15.8195L12.0475 13.5613C11.2416 14.1013 10.2107 14.4204 8.99976 14.4204C6.65567 14.4204 4.67158 12.8372 3.96385 10.71H0.957031V13.0418C2.43794 15.9831 5.48158 18 8.99976 18Z" fill="#34A853"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M3.96409 10.7101C3.78409 10.1701 3.68182 9.59325 3.68182 9.00007C3.68182 8.40689 3.78409 7.83007 3.96409 7.29007V4.95825H0.957273C0.347727 6.17325 0 7.5478 0 9.00007C0 10.4523 0.347727 11.8269 0.957273 13.0419L3.96409 10.7101Z" fill="#FBBC05"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M8.99976 3.57955C10.3211 3.57955 11.5075 4.03364 12.4402 4.92545L15.0216 2.34409C13.4629 0.891818 11.4257 0 8.99976 0C5.48158 0 2.43794 2.01682 0.957031 4.95818L3.96385 7.29C4.67158 5.16273 6.65567 3.57955 8.99976 3.57955Z" fill="#EA4335"></path></svg>
            Sign up with Google
          </a>
        </div>
        <div class="text-center">
          <a class="btn btn-outline-dark btn-block" :href="urlfacebook">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M20 10.0611C20 4.50451 15.5229 0 10 0C4.47715 0 0 4.50451 0 10.0611C0 15.0829 3.65686 19.2452 8.4375 20V12.9694H5.89844V10.0611H8.4375V7.84452C8.4375 5.32296 9.93043 3.93012 12.2146 3.93012C13.3087 3.93012 14.4531 4.12663 14.4531 4.12663V6.60261H13.1921C11.9499 6.60261 11.5625 7.37816 11.5625 8.17381V10.0611H14.3359L13.8926 12.9694H11.5625V20C16.3431 19.2452 20 15.0829 20 10.0611Z" fill="#1877F2"></path></svg>
            Sign up with Facebook
          </a>
        </div>
        <div class="text-center my-3"><strong>or</strong></div>
        <form @submit.prevent>
          <div class="form-group">
            <!-- Text input-->
            <div class="control-group">
              <div class="bg-danger p-1 my-2 text-white" v-if="errors.emailExists">
                An account with this email already exists. Please <a data-open="login-modal">Sign In</a>.
              </div>
              <div class="bg-danger p-1 my-2 text-white" v-if="errors.fields.email">
                <ul class="mb-0 list-unstyled pl-2"><li v-for="err in errors.fields.email" v-text="err"></li></ul>
              </div>
              <div v-cloak class="bg-danger p-1 my-2 text-white"
                   v-if="!data.email && emailBlurred">
                Please enter a valid email address
              </div>
              <label class="control-label" for="id_signup_email">E-mail</label>
              <div class="controls">
                <input type="email" class="form-control" v-enter-press="{fn: doCreateAccount}"
                       maxlength="255" placeholder="enter your email" required v-model="data.email"
                       v-once-blured once-blured="emailBlurred" id="id_signup_email">
              </div>
            </div>
            <div class="control-group mt-3">
              <div class="controls">
                <a class="btn btn-primary" v-enter-press="{fn: doCreateAccount}" tabindex="0"
                   :class="{disabled: !formValid || creatingAccountInProgress}" id="join-modal-btn-submit"
                   @click="doCreateAccount($event)">
                  Sign Up
                </a>
              </div>
            </div>
            <div class="item">
              <a class="float-left" @click="showModal('signin', true)">
                Already a member? <span class="text-primary">Sign In</span>
              </a>
            </div>
          </div>
        </form>
      </div>
    </b-modal>
  </div>
</template>
<script>
import baseModalComponent from '&/utils/baseModalComponent.js';
import * as helperMod from '&/utils/helpers';
import * as directives from '&/directives/DirectiveBase';

const { onceBlured, enterPress, touchShow } = directives;

const hlp = helperMod.appHelper;
const $ = require('jquery');

const { Server } = helperMod;

(() => {})(hlp.consoleLog, $, touchShow);
/*
errors.emailExists
errors.fields.xxx
*/
export default {
  name: 'signUp',
  mixins: [baseModalComponent],
  props: ['urlgoogle', 'urlfacebook'],
  directives: { onceBlured, enterPress },
  data() {
    return {
      popupKey: 'signup',
      emailBlurred: false,
      showSocialError: false,
      refUrl: false,
      creatingAccountInProgress: false,
      data: {
        email: '',
      },
      errors: {
        all: false,
        fields: false,
      },
    };
  },
  computed: {
    formValid() {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(this.data.email);
    },
  },
  methods: {
    doCreateAccount() {
      // const redirectUrl = collection.redirectUrl || null;
      if (this.creatingAccountInProgress) {
        return;
      }
      this.creatingAccountInProgress = true;
      if (this.formValid) {
        Server.post('/api/signup/', this.data)
          .then((data) => {
            if (data.status === 'OK') {
              window.location.href = '/accounts/thank-you/trial/?ref_url=' + window.location.pathname + window.location.search;
              this.closeModal();
            } else if (data.status === 'failed') {
              this.creatingAccountInProgress = false;
              this.errors = data.errors;
            }
          });
      }
    },
  },
};
</script>
