import { $rootScope } from '&/utils/base';
import * as helpers from '&/utils/helpers';
import baseCtrlMixIn from '&/utils/baseController';
import googleMapsCoaches from '&/components/googleMapsCoaches';
import coach from '&/components/coach';
import jquery from 'jquery';

const $ = jquery;

const hlp = helpers.appHelper;
const { Server } = helpers;

(() => {})(hlp.consoleLog, $, $rootScope, Server);
// googleMapsCoaches

const CtrlCoaches = {
  name: 'CtrlCoaches',
  el: '.ctrl-coaches-controller',
  components: {
    googleMapsCoaches,
    coach,
  },
  mixins: [baseCtrlMixIn],
  data() {
    return {
    };
  },
  created() {
  },
  methods: {
    MarkerClicked(evt) {
      this.showModal('coach-' + evt.pk);
    },
    extractHeight(els) {
      let extractH = 0;
      if (els) {
        const selectors = els.split('|');
        hlp.forEach(selectors, (slc) => {
          extractH += ($(slc).height() || 0);
        });
      }
      return extractH;
    },
    boxStyle(h) {
      return { height: `calc(100vh - ${h}px)` };
    },
  },
};
export default CtrlCoaches;
