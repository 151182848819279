import * as helpers from '&/utils/helpers';
import * as PfBase from '&/utils/base';
import baseCtrlMixIn from '&/utils/baseController';
import appCommentBlock from '&/components/comments';
import maxLines from '&/components/maxLines';
import jquery from 'jquery';

const $ = jquery;
const { $rootScope } = PfBase;

const hlp = helpers.appHelper;
const { Server, Deferred } = helpers;

(() => {})(hlp.consoleLog, $rootScope);

const CtrlMain = {
  name: 'ctrlMain',
  el: '.ctrl-base-controller',
  components: {
    appCommentBlock,
    maxLines,
  },
  mixins: [baseCtrlMixIn],
  data() {
    return {
      testList: ['test1', 'test2'],
      state: 'normal',
      playlistForm: {
        playlist_name_dirty: false,
        playlist_name: '',
        showErrors: false,
      },
      globalMessage: {},
      search_items: [],
      search_results: false,
      commment_items: [],
      comments: false,
      comments_count: 0,
      comments_pager: {},
      search_pager: {},
      notifications_pager: {},
      notifications_history: [],
      historyTab: 'watch',
      currentPlayer: false,
      historySaved: false,
      showPlaylistDropDown: true,
      hpMobileShowMenu: false,
      confirmOrderEnabled: true,
      joinForm: {
        first_name: '',
        last_name: '',
        email: '',
        password1: '',
        stripeToken: '',
      },
      joinFormErrors: {
        genericError: '',
        first_name: '',
        last_name: '',
        email: '',
        password1: '',
      },
      popupVideo: {
        thumb: '',
        videoUrl: '',
        show: false,
      },
      homeVideoPlayer: null,
    };
  },
  created() {
    this.search_results = this.search_items;
    this.comments = this.commment_items;
    $rootScope.$on('YtVideo_play', () => {
      this.savePlayHistory();
    });
    $rootScope.$on('YtVideo_end', () => {
      $('body').addClass('overlay-mode');
    });
    $rootScope.$on('courseVimeoEvent_play', (evt) => {
      window.currentPlayer = evt.vmPlayer;
      $('body').removeClass('overlay-mode');
      evt.vmPlayer.on('ended', () => {
        $('body').addClass('overlay-mode');
      });
    });
    $rootScope.$on('vimeoEvent_play', (evt) => {
      window.currentPlayer = evt.vmPlayer;
      evt.vmPlayer.on('ended', () => {
        $('body').addClass('overlay-mode');
      });
      if (window.Vimeo) {
        const player = new window.Vimeo.Player(evt.element);
        player.on('play', () => {
          this.savePlayHistory();
        });
        player.on('ended', () => {
          $('body').addClass('overlay-mode');
        });
      }
      this.savePlayHistory();
    });
    $rootScope.$on('globalMessage', (evtData) => {
      this.globalMessage = evtData;
      this.globalMessage.show = true;
      setTimeout(() => {
        this.globalMessage.show = false;
        this.$forceUpdate();
      }, 10000);
    });

    window.addEventListener('scroll', this.handleScroll);
  },
  mounted() {
    this.handleScroll();

    if (!this.homeVideoPlayer) {
      const $autoplay = document.querySelector('#id-home-video');
      if ($autoplay) this.homeVideoPlayer = new window.Vimeo.Player($autoplay);
    }

    if (this.homeVideoPlayer) {
      this.homeVideoPlayer.getVideoId().then((vid) => {
        this.homeVideoPlayer.on('ended', () => {
          this.homeVideoPlayer.loadVideo(vid).then(() => {}).catch(() => {});
        });
      }).catch(() => {});
    }
  },
  methods: {
    replayVideo() {
      $('body').removeClass('overlay-mode');
      if (window.currentPlayer) {
        window.currentPlayer.api('play');
      }
      $('a.js-nonclickable').click(() => false);
    },
    SaveToPlaylist(playlist) {
      const data = {
        playlist_id: playlist.id,
        page_id: window.page_id,
        is_checked: playlist.is_checked,
      };
      Server.post(this.getPlaylistUrl(), data)
        .then(() => {
          if (playlist.is_checked) {
            this.notifSuccess('Added To Playlist');
          } else {
            this.notifSuccess('Removed From Playlist.');
          }
        });
    },
    toggleAddPlaylistMenu() {
      this.showAddPlaylist = true;
    },
    hidePlaylist() {
      this.showAddPlaylist = false;
    },
    savePlayHistory() {
      if (this.historySaved) {
        return;
      }
      this.historySaved = true;
      const data = { page_id: window.page_id, user_id: window.user_id };
      Server.post('/api/history/', data).then(() => {
      });
    },
    savePlaylist(evt, playlist) {
      const { playlistForm } = this;

      playlistForm.showErrors = true;
      if (this.playlist.name) {
        Server.post(this.getPlaylistUrl(), playlist)
          .then((data) => {
            this.closeDropdown('#playlist_dropdown');
            if (data.success) {
              this.notifSuccess('Added To Playlist');
              this.getUserPlaylist();
              this.showPlaylistDropDown = false;
              this.$nextTick(() => { // we need to recreate playlist because something is breaking in bootstrap...
                this.showPlaylistDropDown = true;
              });
            } else {
              this.notifError(data.message);
            }

            this.playlist.name = '';
            playlistForm.playlist_name_dirty = false;
          });
        return true;
      }
      if (evt && evt.stopPropagation) {
        evt.stopPropagation();
      }
      return false;
    },
    preventSubmit(evt) {
      evt.preventDefault();
    },
    open_url(url) {
      if (url && url !== '#') {
        window.location.href = url;
      }
    },
    getUserComments(page) {
      this.historyTab = 'comments';
      page = page || 1;
      const url2 = '/api/comments/list/?page=' + page;
      Server.get(url2).then((data) => {
        this.comments = data.comments;
        this.comments_count = 0;
        this.comments_pager = data.paginator;
        hlp.forEach(this.comments, (obj) => {
          if (!obj.is_removed) {
            this.comments_count += 1;
          }
        });
      });
    },
    getUserNotificationsHistory(page) {
      this.historyTab = 'notif';
      page = page || 1;
      const url2 = '/api/notifications/?page=' + page;
      Server.get(url2).then((data) => {
        this.notifications_history = data.notifications;
        this.notifications_pager = data.paginator;
      });
    },
    open_search_page(url) {
      if (url !== '#') {
        window.location.href = '/search/?q=' + url;
      }
    },
    open_comment_page(comment) {
      let url = '';
      if (comment.content_type === 'page') {
        url = '/golf-instruction/' + comment.page_slug;
      }
      if (comment.content_type === 'submission') {
        url = '/swing-analysis/submission/' + comment.object_pk;
      }
      if (url !== '#') {
        window.location.href = url;
      }
    },
    deleteHistoryItem(pk, type, msgAll, msgOne) {
      const promiseObj = new Deferred();
      const typeMap = {
        notif: 'notifications',
        comments: 'comments_view',
        watch: 'history',
      };
      const endpoint = typeMap[type];
      const callDelete = (url) => {
        Server.delete(url).then(() => {
          promiseObj.resolve(promiseObj);
        }, (dt) => {
          promiseObj.reject(dt);
        });
        // setTimeout(() => { promiseObj.resolve(); });
      };
      if (endpoint) {
        let url = `/api/${endpoint}/`;
        let confirmMsg = null;
        if (pk) {
          url = `${url}${pk}/`;
          confirmMsg = msgOne;
        } else {
          confirmMsg = msgAll;
        }
        if (confirmMsg) {
          this.confirm(confirmMsg).then((result) => {
            if (!result) {
              promiseObj.reject();
            } else {
              callDelete(url);
            }
          });
        } else {
          callDelete(url);
        }
      } else {
        setTimeout(() => {
          promiseObj.reject();
        });
      }
      return promiseObj.promise;
    },
    deleteNotification(pk, page) {
      this.deleteHistoryItem(pk, 'notif', 'Do you want to clear your Notifications history?',
        'Do you want to remove this Notification?').then(() => {
        this.getUserNotificationsHistory(page);
      }, () => {});
    },
    deleteComment(pk, page) {
      this.deleteHistoryItem(pk, 'comments', 'Do you want to clear your Comments history?',
        'Do you want to remove this comment?').then(() => {
        this.getUserComments(page);
      }, () => {});
    },
    deleteWatchHistory(pk) { // , page
      this.deleteHistoryItem(pk, 'watch', 'Do you want to clear your watch history?').then(() => {
        this.reloadPage();
      }, () => {});
    },
    openPopupVideo(id, thumb, videoUrl) {
      this.popupVideo.thumb = thumb;
      this.popupVideo.videoUrl = videoUrl;
      this.popupVideo.show = true;
      this.$bvModal.show(id);
    },
    closeMobileNav(el) {
      $(el).collapse('hide');
    },
    formJoinInvalid() {
      let isValid = true;
      const names = ['first_name', 'last_name', 'email', 'password1'];
      hlp.forEach(names, (name) => {
        isValid = isValid && this.joinForm[name];
      });
      return !isValid;
    },
    submitJoinForm(evt, sku) {
      const el = evt.target;
      const form = $(el).closest('form');
      const stripeToken = form.find('input[name=stripeToken]').val();
      this.joinForm.stripeToken = stripeToken;
      hlp.forEach(this.joinFormErrors, (v, k) => {
        this.joinFormErrors[k] = '';
      });
      Server.post(`/accounts/api-join/${sku}/`, this.joinForm).then((resp) => {
        if (resp.status === 'OK') {
          if (resp.url) {
            window.location.href = resp.url;
          }
        } else {
          // eslint-disable-next-line
          if (resp.errors) {
            this.joinFormErrors = resp.errors;
          } else if (resp.error) {
            this.joinFormErrors.genericError = resp.error;
          }
        }
      });
    },
    extendFeedbacks(evt) {
      $(evt.target).parent().children().first()
        .show();
      $(evt.target).hide();
    },
    scrollToElement(selector) {
      const el = this.$el.querySelector(selector);
      if (el) el.scrollIntoView({ behavior: 'smooth' });
    },
    checkVideos(videoId) {
      const element = $(`#${videoId}`);
      if (element.length) {
        const tolerancePixel = 40;
        const scrollTop = $(window).scrollTop() + tolerancePixel;
        const scrollBottom = $(window).scrollTop() + $(window).height() - tolerancePixel;
        const yTopMedia = element.offset().top;
        const yBottomMedia = element.height() + yTopMedia;

        // In case of Vimeo player
        /*
        const player = new window.Vimeo.Player(document.getElementById(videoId));
        if (scrollTop < yTopMedia && scrollBottom > yBottomMedia) {
          player.getPaused().then((paused) => {
            if (paused) player.play();
          }).catch(() => {});
        } else {
          player.getPaused().then((paused) => {
            if (!paused) player.pause();
          }).catch(() => {});
        }
        */

        const player = document.getElementById(videoId);
        if (scrollTop < yTopMedia && scrollBottom > yBottomMedia) {
          player.play();
        } else {
          player.pause();
        }
      }
    },
    handleScroll() {
      // Play videos in home page when user scroll the page
      this.checkVideos('id-preview-video-expect-1');
      this.checkVideos('id-preview-video-expect-2');
      this.checkVideos('id-preview-video-expect-3');
      this.checkVideos('id-preview-video-expect-4');
      // this.checkVideos('id-meet-instructor-video');
    },
    playHomeVideo() {
      this.homeVideoPlayer.setVolume(1).then(() => {}).catch(() => {});
      this.homeVideoPlayer.getPaused().then((paused) => {
        if (paused) {
          this.homeVideoPlayer.play();
        } else {
          this.homeVideoPlayer.pause();
        }
      });
    },
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
};
export default CtrlMain;
