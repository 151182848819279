// import * as PfBase from '&/utils/base';
import CtrlMain from '&/controllers/CtrlMain';
import promoCodeMixIn from '&/controllers/promoCodeMixIn';

const ctrlPayment = {
  name: 'ctrlPayment',
  el: '.ctrl-payment-controller',
  components: {
  },
  mixins: [promoCodeMixIn, CtrlMain],
  data() {
    return {
      confirmSubscription: false,
      formAjaxUrl: false,
    };
  },
  created() {
  },
  methods: {
    setFormAjaxUrl(url) {
      this.formAjaxUrl = url;
    },
    doCheckout(baseUrl) {
      const url = this.urlExtendPromoParam(baseUrl);
      window.location.href = url;
    },
    setConfirmSubscription(val) {
      this.confirmSubscription = val;
    },
    storeField() {
      window.joinFormChanged = true;
    },
    joinFormSubmitted(data, element, attrs) {
      window.joinFormChanged = false;
      window.location.replace(attrs.urlOnSuccess);
    },
  },
};
export default ctrlPayment;
