import * as helpers from '&/utils/helpers';
import * as PfBase from '&/utils/base';
import CtrlMain from '&/controllers/CtrlMain.js';
import jquery from 'jquery';

const $ = jquery;
const { $rootScope } = PfBase;

const hlp = helpers.appHelper;
const { Server, Deferred } = helpers;

(() => {})(hlp.consoleLog, $rootScope, $, Server, Deferred);

const CtrlCourses = {
  name: 'ctrlCourses',
  el: '.ctrl-course-controller',
  components: {
  },
  mixins: [CtrlMain],
  data() {
    return {
      courseTabIdx: 1,
      lesson: false,
      videoState: 'pause',
    };
  },
  created() {
    $rootScope.$on('courseResized', (evt) => {
      if (evt.enlarged) {
        if (hlp.testMinWidth(993)) {
          if (this.courseTabIdx === 0) {
            this.courseTabIdx = 1;
          }
        }
      } else if (hlp.testMaxWidth(992)) {
        if (this.courseTabIdx === 1) {
          this.courseTabIdx = 0;
        }
      }
    });
    /*
    setTimeout(() => {
      $rootScope.$emit('courseVimeoEvent_play', {});
    }, 10000);
    setTimeout(() => {
      $rootScope.$emit('courseVimeoEvent_pause', {});
    }, 20000);
    */
    $rootScope.$on('courseVimeoEvent_pause', () => {
      if (this.lesson) {
        this.videoState = 'pause';
      }
    });
    $rootScope.$on('courseVimeoEvent_ended', () => {
      if (this.lesson) {
        this.videoState = 'ended';
      }
    });
    const logPlayLesson = () => {
      if (this.lesson) {
        this.markWatched(this.lesson);
        this.videoState = 'play';
        this.$nextTick(() => {
          this.$forceUpdate();
        });
      }
    };
    $rootScope.$on('courseVimeoEvent_play', () => {
      logPlayLesson();
    });
    $rootScope.$on('YtVideo_play', () => {
      logPlayLesson();
    });
  },
  methods: {
    changeVideoState(action) {
      const iframe = $('[video-events-event-prefix="courseVimeoEvent_"]').get(0);
      const vmPlayer = new window.Vimeo.Player(iframe);
      if (action === 'play') {
        this.videoState = 'play';
        vmPlayer.play();
      } else {
        this.videoState = 'pause';
        vmPlayer.pause();
      }
    },
    setLesson(lesson) {
      this.lesson = lesson;
    },
    markWatched(lesson) {
      Server.post(`courses/log/${lesson}/`).then(() => {});
    },
    buyCourse(courseId) {
      if (this.$root.isAuthenticated) {
        window.location.href = `/accounts/checkout/${courseId}`;
      } else {
        window.location.href = `/accounts/join/${courseId}`;
      }
    },
  },
};
export default CtrlCourses;
