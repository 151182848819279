<template>
  <div>
    <b-modal :id="myId" :no-stacking="stopStacking" hide-footer>
      <h3>Please enter your name</h3>
      <div class="form-container">
        <form v-form-enter-press form-enter-press="#user-details-modal-btn-submit">
          <fieldset class="form-group">
            <!-- Text input-->
            <div class="control-group">
              <div class="bg-danger p-3 mb-2 text-white" v-if="errors.first_name"
                   v-text="errors.first_name"></div>

              <div class="controls">
                <input v-enter-press enter-press="#join-modal-btn-submit" type="text" class="form-control"
                       maxlength="30" name="first_name" placeholder="First name" required
                       v-model="data.first_name">
              </div>
            </div>

            <!-- Text input-->
            <div class="control-group mt-1">
              <div class="bg-danger p-3 mb-2 text-white" v-if="errors.last_name"
                   v-text="errors.last_name"></div>
              <div class="controls">
                <input v-enter-press enter-press="#join-modal-btn-submit" type="text" class="form-control"
                       maxlength="30" placeholder="Last name" required
                       v-model="data.last_name">
              </div>
            </div>
            <div class="control-group mt-3">
              <div class="controls">
                <a class="btn btn-primary" tabindex="0"
                   id="user-details-modal-btn-submit" :class="{disabled: !formValid || updatingAccountInProgress}"
                   @click="doUpdateAccount($event)">Save</a>
              </div>
            </div>
          </fieldset>
        </form>
      </div>
    </b-modal>
  </div>
</template>
<script>
import baseModalComponent from '&/utils/baseModalComponent.js';
import * as helperMod from '&/utils/helpers';
import * as directives from '&/directives/DirectiveBase';
import * as PfBase from '&/utils/base';

const { $rootScope } = PfBase;
const { enterPress } = directives;

const hlp = helperMod.appHelper;
const $ = require('jquery');

const { Server } = helperMod;

(() => {})(hlp.consoleLog, $);
export default {
  name: 'userDetails',
  mixins: [baseModalComponent],
  props: [],
  directives: { enterPress },
  data() {
    return {
      modalVisible: false,
      popupKey: 'updateaccount',
      updatingAccountInProgress: false,
      data: {
        first_name: '',
        last_name: '',
      },
      errors: {},
    };
  },
  computed: {
    formValid() {
      return this.data.first_name && this.data.last_name;
    },
  },
  methods: {
    onHidden() {
      this.data.first_name = '';
      this.data.last_name = '';
    },
    doUpdateAccount() {
      if (this.updatingAccountInProgress) {
        return;
      }
      this.updatingAccountInProgress = true;
      if (this.formValid) {
        Server.post('/api/update_user/', this.data).then((data) => {
          if (data.status === 'OK') {
            if (data.user) {
              $rootScope.$emit('user-details-modal-close', true);
              this.closeModal();
            }
          } else if (data.status === 'failed') {
            $rootScope.$emit('user-details-modal-close', false);
            this.updatingAccountInProgress = false;
            this.errors = data.errors;
          }
        });
      }
    },
  },
};
</script>
