<template>
  <div>
    <div v-text="text"></div>
  </div>
</template>
<script>
import * as helperMod from '&/utils/helpers';
import jquery from 'jquery';

const helpers = helperMod.appHelper;
const $ = jquery;

(() => {})(helpers.consoleLog, $);

export default {
  name: 'maxLines',
  mixins: [],
  props: ['text', 'maxLines', 'maxLinesWatch', 'maxLinesSwitch'],
  data() {
    return {
    };
  },
  created() {
    if (typeof this.maxLinesWatch === 'undefined') {
      this.maxLinesSwitch = true;
      this.limitSize();
    }
  },
  watch: {
    maxLinesWatch() { // watchFor
      if (typeof this.maxLinesWatch !== 'undefined') {
        setTimeout(() => {
          this.limitSize();
        }, 0);
      }
    },
    maxLinesSwitch(val) {
      if (val) {
        setTimeout(() => {
          this.limitSize();
        }, 0);
      } else {
        setTimeout(() => {
          this.unlimitSize();
        }, 0);
      }
    },
  },
  methods: {
    limitSize() {
      if (!this.maxLinesSwitch) {
        return;
      }
      const element = this.$el;
      const lineHeight = parseInt($(element).css('line-height'), 10);
      const lines = parseInt(this.maxLines, 10);
      const currentHeight = parseInt($(element).height(), 10);
      if (lineHeight && lines) {
        const theHeight = lineHeight * lines + 2;
        if (currentHeight > theHeight) {
          $(element).css({
            height: theHeight,
            overflow: 'hidden',
          });
          this.maxLinesMode = true;
        }
      }
    },
    unlimitSize() {
      $(this.$el).css({
        height: 'auto',
        overflow: 'visible',
      });
    },
  },
};
</script>
