import { PfVue, $rootScope } from '&/utils/base';
import * as helpers from '&/utils/helpers';
import baseCtrlMixIn from '&/utils/baseController';
import machinaAttachment from '&/components/machinaAttachment';
import jquery from 'jquery';

const $ = jquery;

const hlp = helpers.appHelper;
const { Server } = helpers;

(() => {})(hlp.consoleLog, $, PfVue);

function beforeUnloadFn(e) {
  // Cancel the event
  e.preventDefault();
  // Chrome requires returnValue to be set
  e.returnValue = 'The post has not been saved. Are you sure to leave page before saving?';
}

const CtrlMachina = {
  name: 'CtrlMachina',
  el: '.ctrl-machina-controller',
  components: {
    machinaAttachment,
  },
  mixins: [baseCtrlMixIn],
  data() {
    return {
      xhrMap: {},
      imageMap: {},
      pageModified: false,
      isFormSubmitting: false,
      attachmentSeq: 1,
      isSubject: false,
      isContent: false,
      attachments: {
        currAttachments: false,
        group: 0,
        fileItems: [],
        maxFiles: false,
        s3data: false,
        bucket: false,
      },
      attachmentModalCollection: { showImage: false, showVideos: false, showFile: false },
    };
  },
  created() {
    $rootScope.$on('attachment-view-modal-close', () => {
      this.attachmentModalCollection = { showImage: false, showVideos: false, showFile: false };
    });
    $rootScope.$on('contentModified', () => {
      if (!this.pageModified) {
        this.pageModified = true;
        window.addEventListener('beforeunload', beforeUnloadFn);
      }
    });
    this.addNewAttachment();
    this.getAttachments();
  },
  mounted() {
    // validate the Topic Form
    $(document).on('input', '#id_subject, #id_content', () => {
      this.toggleButtonActive();
    });
  },
  methods: {
    toggleButtonActive() {
      if ($('#id_subject').val() && $('#id_content').val()) {
        this.removeDisable();
      } else {
        this.disableButton();
      }
    },
    disableButton() {
      $('#forum_submit, #update_topic, #partial_form, #conversation_form').addClass('disabled');
    },
    removeDisable() {
      $('#forum_submit, #update_topic, #partial_form, #conversation_form').removeClass('disabled');
    },
    canAddFile() {
      if (this.attachments.maxFiles === -1) {
        return true;
      }
      return this.attachments.maxFiles && this.attachments.maxFiles >= (
        this.attachments.fileItems.length + (this.attachments.currAttachments || []).length);
    },
    showAttachmentModal(urls, type) {
      this.attachmentModalCollection.showFile = false;
      this.attachmentModalCollection.showImage = false;
      this.attachmentModalCollection.showVideos = false;
      if (type === '1') {
        [this.attachmentModalCollection.showImage] = urls;
      } else if (type === '2') {
        this.attachmentModalCollection.showVideos = urls;
      } else {
        [this.attachmentModalCollection.showFile] = urls;
      }
      this.showModal('machinaattachment');
    },
    addNewAttachment() {
      const newAtt = {
        uploading: false,
        progress: 0,
        processing: false,
        showProgress: false,
        showCancel: false,
        error: 0,
        procError: false,
        showInput: true,
        rendInput: true,
        fileType: false,
        thumbnail: false,
        image: false,
        pk: false,
        model: null,
        tmpPk: false,
        tmpData: false,
      };
      const currAtt = hlp.deepCopy(newAtt);
      currAtt.idx = this.attachmentSeq;
      currAtt.name = 'file_' + currAtt.idx;
      this.attachmentSeq += 1;
      this.attachments.fileItems.push(currAtt);
    },
    getAttachments() {
      const pk = $('[data-post-id]').data('post-id') || '0';
      Server.get('/machina-api/attachments/' + pk + '/').then((data) => {
        this.attachments.currAttachments = data.attachments;
        this.attachments.maxFiles = data.max_files;
      });
    },
    deleteCurrAttachment(pk) {
      this.confirm('Are you sure to delete this attachment?').then((result) => {
        if (result) {
          Server.delete('/machina-api/attachments/' + pk + '/').then((data) => {
            this.attachments.currAttachments = this.attachments.currAttachments.filter((x) => x.pk !== data.pk);
          });
        }
      });
    },
    rmFailedAttachment(idx) {
      setTimeout(() => {
        this.attachments.fileItems = this.attachments.fileItems.filter((x) => x.idx !== idx);
      }, 2000);
    },
    getPostParent(parentId) {
      const url = `?post=${parentId}#${parentId}`;
      if (!$('#' + parentId).length) {
        return url;
      }
      return '#' + parentId;
    },
    highlightPost(parentId) {
      const parentBlock = $('#' + parentId);
      if (parentBlock.length) {
        setTimeout(() => {
          parentBlock.addClass('highlight-me');
          setTimeout(() => {
            parentBlock.removeClass('highlight-me');
          }, 1500);
        }, 500);
      }
    },
    postFormSubmit(evente, elSel) {
      if (this.isFormSubmitting) {
        return;
      }
      let isProcessing = false;
      hlp.forEach(this.attachments.fileItems, (item) => {
        if (item.processing || item.uploading) {
          isProcessing = true;
        }
      });
      if (isProcessing) {
        return;
        // this.alert('Cannot submit form. Some files are still processing...').then();
      }
      if (!$('#id_subject').val() || !$('#id_content').val()) return;
      window.removeEventListener('beforeunload', beforeUnloadFn);
      if (elSel) {
        this.isFormSubmitting = true;
        $(elSel).click();
      }
    },
    uploadToS3(element, topicId) {
      const target = $('input[name=' + element.name + ']').get(0);
      const $this = this;
      const uploadFn = (el) => {
        const inputFile = target.files[0];
        if (this.attachments.maxSize) {
          if (this.attachments.maxSize < inputFile.size) {
            this.alert('Error! File is too big. Max allowed size is: ' + hlp.formatFileSize(this.attachments.maxSize));
            return;
          }
        }
        $this.disableButton();
        $rootScope.$emit('contentModified');
        Server.post('/machina-api/new-attachment/', { group: this.attachments.group, inp_file: inputFile.name })
          .then((dt2) => {
            el.showProgress = true;
            const formData = new FormData();
            // formData.append('file', inputFile);
            hlp.forEach(this.attachments.s3data, (itm) => {
              let [, v] = itm;
              if (itm[0] === 'key') {
                // eslint-disable-next-line
                v = v.replace('${filename}', dt2.key);
              } else if (itm[0] === 'file') {
                v = inputFile;
              } else if (itm[0] === 'content-type') {
                v = inputFile.type;
              }
              formData.append(itm[0], v);
            });
            el.tmpPk = dt2.pk;
            el.tmpData = dt2.tmp_data;
            const xhr = new window.XMLHttpRequest();
            xhr.responseType = 'json';
            this.xhrMap[el.name] = xhr;
            el.showCancel = true;
            el.showInput = false;
            this.addNewAttachment();
            const completeResp = (el5) => {
              el5.pk = el5.tmpData.pk;
              this.xhrMap[el5.name] = null;
              const runImgLoad = (el5a, nr, src) => {
                if (nr > 30) {
                  return;
                }
                this.imageMap[el5a.name] = new Image();
                this.imageMap[el5a.name].onload = function onLoadFn() {
                  el5a.fileType = el5a.tmpData.file_type;
                  el5a.thumbnail = el5a.tmpData.thumbnail_name;
                  el5a.image = el5a.tmpData.file_name;
                  el5a.processing = false;
                  $this.toggleButtonActive();
                  $this.$forceUpdate();
                };
                this.imageMap[el5a.name].onerror = function onErrFn() {
                  setTimeout(() => {
                    runImgLoad(el5a, nr + 1, src);
                  }, 1000);
                };
                this.imageMap[el5a.name].src = src;
              };
              // runImgLoad(el5, 1, el5.tmpData.file_name);
              runImgLoad(el5, 1, el5.tmpData.thumbnail_name);
              Server.put('/machina-api/new-attachment/', { pk: el5.pk }).then(() => {});
            };
            const completeFn = (el2) => {
            // xhrMap[el2.name] = null;
              el2.showCancel = false;
              setTimeout(() => {
                this.$forceUpdate();
                el2.rendInput = false;
              });
            };
            xhr.upload.addEventListener('load', () => {
              el.uploading = false;
              completeResp(el);
              completeFn(el);
            });
            xhr.upload.addEventListener('progress', (evt2) => {
              const percentComplete = evt2.loaded / evt2.total;
              el.progress = 100.0 * percentComplete;
              if (el.progress >= 99) {
                el.processing = true;
                el.uploading = false;
              }
              this.$forceUpdate();
            }, false);
            xhr.upload.addEventListener('error', () => {
              el.error = 1;
              el.uploading = false;
              completeFn(el);
            }, false);
            xhr.upload.addEventListener('abort', () => {
              el.error = 2;
              el.uploading = false;
              completeFn(el);
            }, false);
            xhr.open('POST', `//${this.attachments.bucket}.s3.amazonaws.com/`);
            xhr.send(formData);
            el.uploading = true;
          });
      };
      if (this.attachments.group) {
        uploadFn(element);
      } else {
        Server.get('/machina-api/attachment-group/?t=' + (topicId || '')).then((data) => {
          this.attachments.group = data.pk;
          this.attachments.s3data = data.s3data;
          this.attachments.bucket = data.bucket;
          this.attachments.maxSize = data.max_size;
          uploadFn(element);
        });
      }
    },
    abortUpload(el) {
      this.xhrMap[el.name].abort();
      el.showCancel = false;
    },
    removeTmpFile(el) {
      Server.get('/machina-api/del-attachment/' + el.pk + '/').then((data) => {
        if (data.status === 'OK') {
          this.attachments.fileItems = this.attachments.fileItems.filter((x) => x.pk !== el.pk);
        }
      });
    },
  },
};
export default CtrlMachina;
