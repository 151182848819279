import * as helpers from '&/utils/helpers';
import { $rootScope } from '&/utils/base';
import maxLines from '&/components/maxLines';
import baseCtrlMixIn from '&/utils/baseController';
import { BIconTrash, BIconPencil } from 'bootstrap-vue';
import machinaAttachment from '&/components/machinaAttachment';
import jquery from 'jquery';
import * as directives from '&/directives/DirectiveBase';

const textareaExpand = directives.textareaExpandDirective;

const hlp = helpers.appHelper;
const { Server } = helpers;
const $ = jquery;

(() => {
})(hlp.consoleLog, $);

export default {
  name: 'appCommentBlock',
  template: '#component-comment-block',
  mixins: [baseCtrlMixIn],
  props: ['contenttype', 'contentid', 'gravatar', 'username'],
  directives: { textareaExpand },
  components: {
    maxLines,
    BIconTrash,
    BIconPencil,
    machinaAttachment,
  },
  data() {
    return {
      commentCollection: {},
      currentParent: '',
      commentList: [],
      currentComment: '',
      commentsLoaded: false,
      activeRootButtons: false,
      sendingInProgress: false,
      commentsCount: 0,
      showUnauthorizedMessage: false,
      showInactiveMessage: false,
      timer: 0,
      root_paths: {},
      ticker: false,
      can_edit: false,
      can_delete: false,
      limit_time_edit: false,

      xhrMap: {},
      imageMap: {},
      pageModified: false,
      attachmentSeq: 1,
      attachments: {
        currAttachments: false,
        group: 0,
        fileItems: [],
        maxFiles: 5,
        s3data: false,
        bucket: false,
      },
      attachmentModalCollection: { showImage: false, showVideos: false, showFile: false },
      removePKs: [],
      uploadedAttachmentsCount: 0,
      isLoadMoreAvailable: true,
      showingCount: 10,
    };
  },
  computed: {
    availableComments() {
      return this.commentList.slice(0, this.showingCount);
    },
  },
  created() {
    this.loadComments();
    $rootScope.$on('attachment-view-modal-close', () => {
      this.attachmentModalCollection = { showImage: false, showVideos: false, showFile: false };
    });
    $rootScope.$on('contentModified', () => {
      if (!this.pageModified) {
        this.pageModified = true;
        window.addEventListener('beforeunload', this.beforeUnloadFn);
      }
    });

    $rootScope.$on('cancelModified', () => {
      this.pageModified = false;
      window.removeEventListener('beforeunload', this.beforeUnloadFn);
    });

    $rootScope.$on('pageScrolled', () => {
      if (hlp.isScrolledIntoView(this.$el)) {
        if (!this.commentsLoaded) {
          this.loadComments();
          this.commentsLoaded = true;
        }
      }
    });
    this.addNewAttachment();

    // Remove uploaded files from S3 before refresh or redirect page
    window.addEventListener('unload', () => {
      this.cancelService();
      this.initAttachments();
    });
  },
  watch: {},
  methods: {
    beforeUnloadFn(e) {
      // Cancel the event
      e.preventDefault();
      // Chrome requires returnValue to be set
      e.returnValue = 'The post has not been saved. Are you sure to leave page before saving?';
    },
    showAttachmentModal(urls, type) {
      this.attachmentModalCollection.showFile = false;
      this.attachmentModalCollection.showImage = false;
      this.attachmentModalCollection.showVideos = false;

      if (type === 1) {
        [this.attachmentModalCollection.showImage] = urls;
      } else if (type === 2) {
        this.attachmentModalCollection.showVideos = urls;
      } else {
        [this.attachmentModalCollection.showFile] = urls;
      }
      this.showModal('machinaattachment');
    },
    initAttachments() {
      this.attachmentSeq = 1;
      this.attachments.group = 0;
      this.attachments.fileItems = this.attachments.fileItems.filter((el) => (this.attachments.fileItems.indexOf(el) === this.attachments.fileItems.length - 1));
    },
    initNewComment(comment, onlyValue) {
      const result = `+${comment.username} `;
      if (!onlyValue) {
        comment.myComment = result;
      }
      return result;
    },
    getIsAuthenticated() {
      return this.$root.isAuthenticated;
    },
    resetCommentItem(idx) {
      this.commentList[idx].myComment = '';
      this.commentList[idx].showAnswerForm = false;
    },
    setCurrentComment(val, parent) {
      this.currentComment = val;
      this.currentParent = parent || '';
    },
    runTicker() {
      if (!this.ticker && this.limit_time_edit) {
        this.ticker = setInterval(() => {
          this.timer += 1;
        }, 1000);
      }
    },
    submitComment(comment, idx) {
      let val = '';
      let parent = '';
      let initComment = null;
      if (typeof idx !== 'undefined') {
        val = comment.myComment;
        parent = comment.id;
        initComment = this.initNewComment(comment, true);
      } else {
        val = comment;
      }
      if (this.sendingInProgress) {
        return;
      }
      val = $.trim(val || '');
      if (!val || (initComment && initComment.indexOf(val) === 0)) {
        return;
      }
      const calback = (val2, parent2, idx2) => {
        this.setCurrentComment(val2, parent);
        if (val2) {
          this.sendingInProgress = true;
          setTimeout(() => {
            const postData = {
              object_pk: this.contentid,
              content_type: this.contenttype,
              parent: this.currentParent || null,
              comment: this.currentComment,
              group: this.attachments.group,
            };
            Server.post('/api/comments/add/', postData)
              .then((data) => {
                const item = data.comment;
                if (data.status === 'OK') {
                  if (!parent2) {
                    this.commentCollection.rootComment = '';
                    this.attachmentSeq = 1;
                    setTimeout(() => {
                      $('.comment-container .root-form textarea').trigger('input');

                      // this.continueProcessing();
                    }, 100);
                  } else {
                    item.parent = parent2;
                    this.resetCommentItem(idx2);
                    comment.myComment = '';
                    comment.showAnswerForm = false;
                  }

                  this.loadComments();
                  this.runTicker();
                  $rootScope.$emit('cancelModified');
                } else if (data.status === 'not_authenticated') {
                  // TODO !!!!!!!!!!!!!!!!!!
                  this.showUnauthorizedMessage = true;
                } else if (data.status === 'not_active') {
                  // TODO !!!!!!!!!!!!!!!!!!
                  this.showInactiveMessage = true;
                }

                this.initAttachments();
                this.sendingInProgress = false;
              });
          }, 0);
        }
      };

      if (this.$root.allowPostContent) {
        this.$root.allowPostContent().then(() => {
          calback(val, parent, idx);
        }, 0);
      } else {
        calback(val, parent, idx);
      }
    },
    loadComments() {
      Server.get('/api/comments/list/' + this.contenttype + '/' + this.contentid)
        .then((data) => {
          hlp.forEach(data.comments, (comment) => {
            hlp.forEach(comment.medias, (media) => {
              media.removeProcessing = false;
            });
          });
          this.commentList = data.comments;
          this.commentsCount = data.total_count;

          if (this.showingCount >= this.commentsCount) this.isLoadMoreAvailable = false;

          this.timer = 0;
          this.can_edit = data.can_edit;
          this.can_delete = data.can_delete;
          this.limit_time_edit = data.limit_time_edit;

          hlp.forEach(data.comments, (item) => {
            const params = {
              showAnswerForm: false,
              myComment: '',
              editMode: false,
              editValue: '',
              isCut: true,
              showMoreBtn: false,
              cutComment: '',
            };
            hlp.forEach(params, (v, k) => {
              this.$set(item, k, v);
            });
            // eslint-disable-next-line
            if (!this.root_paths.hasOwnProperty(item.root_path)) {
              this.root_paths[item.root_path] = item.id;
              this.$set(item, 'sort_order', item.id);
            }
          });
          this.runTicker();
          setTimeout(() => {
            if (window.location.hash) {
              $('html,body').animate({
                scrollTop: $(window.location.hash).offset().top,
              },
              'slow');
            }
          }, 500);
        });
    },
    closeAllCommentEdit(comment) {
      this.uploadedAttachmentsCount = 0;
      if (comment) {
        comment.showAnswerForm = false;
      }

      this.activeRootButtons = false;

      hlp.forEach(this.commentList, (el) => {
        el.editMode = false;

        if (comment && comment === el) el.showAnswerForm = comment.showAnswerForm;
        else el.showAnswerForm = false;
      });
    },
    switchAnswerForm(comment) {
      this.closeAllCommentEdit(comment);
      this.initAttachments();
      const odlVal = comment.showAnswerForm;
      hlp.forEach(this.commentList, (item) => {
        this.$set(item, 'showAnswerForm', false);
      });
      if (!odlVal) {
        this.initNewComment(comment);
      }
      this.$set(comment, 'showAnswerForm', !(odlVal || false));
      this.$nextTick(() => {
        $(`textarea[pk=${comment.id}]`).focus();
      });
    },
    cancelComment(comment) {
      this.cancelService();
      this.initAttachments();

      if (comment) {
        comment.showAnswerForm = false;
      } else {
        this.activeRootButtons = false;
      }
      $rootScope.$emit('cancelModified');
    },
    showRootButtons() {
      this.closeAllCommentEdit();

      this.activeRootButtons = true;
      if (!this.attachments.fileItems.length) this.addNewAttachment();
    },
    deleteComment(pk) {
      this.$root.confirm('Do you want to delete this comment?').then((accept) => {
        if (accept) {
          Server.delete('/api/comments/delete/del-s3/' + parseInt(pk, 10) + '/')
            .then((data) => {
              if (data.status === 'permission_denied') {
                this.$root.alert('Sorry, you are not allowed to delete this comment.').then(() => {
                });
              }
            });

          Server.delete('/api/comments/delete/' + parseInt(pk, 10) + '/')
            .then((data) => {
              if (data.status === 'OK') {
                this.loadComments();
              } else if (data.status === 'permission_denied') {
                this.$root.alert('Sorry, you are not allowed to delete this comment.').then(() => {
                });
              }
            });
          $rootScope.$emit('cancelModified');
        }
      });
    },
    editComment(comment) {
      this.closeAllCommentEdit();
      this.initAttachments();

      this.uploadedAttachmentsCount = comment.medias.length;

      comment.editValue = comment.comment;
      comment.editMode = true;
    },
    cancelService() {
      hlp.forEach(this.attachments.fileItems, (el) => {
        if (el.pk) this.removePKs.push(el.pk);
      });

      if (this.attachments.group && this.removePKs.length) {
        Server.post('/api/comments/cancel/', { group: this.attachments.group, pks: this.removePKs }).then((data) => {
          if (data.status === 'OK') {
            this.removePKs = [];
          }
        });
      }
    },
    cancelEdit(comment) {
      this.cancelService();
      this.initAttachments();
      comment.editMode = false;

      hlp.forEach(comment.medias, (item) => {
        item.removeProcessing = false;
      });
      $rootScope.$emit('cancelModified');
    },
    sendEditComment(comment) {
      this.sendingInProgress = true;
      setTimeout(() => {
        // Server.post('api/comments/edit/del-s3/' + parseInt(comment.id, 10) + '/', { pks: this.removePKs })
        //   .then((data) => {
        //     if (data.status === 'OK') {
        //       console.log('Removed!');
        //     }
        //   });

        Server.post('/api/comments/edit/' + parseInt(comment.id, 10) + '/',
          { comment: comment.editValue, pks: this.removePKs, group: this.attachments.group })
          .then((data) => {
            this.initAttachments();
            this.sendingInProgress = false;
            this.removePKs = [];
            if (data.status === 'OK') {
              this.loadComments();
            } else if (data.status === 'timeout') {
              this.$root.alert('I am sorry, but the changes have not been saved. Reason: timeout.').then(() => {
              });
            } else if (data.status === 'permission_denied') {
              this.$root.alert('I am sorry, but the changes have not been saved. Reason: Permission denied.').then(() => {
              });
            } else if (data.status === 'invalid_form') {
              this.$root.alert('I am sorry, but the changes have not been saved. Reason: Invalid or empty form.').then(() => {
              });
            }

            $rootScope.$emit('cancelModified');
          });
      }, 0);
    },
    getComment(comment) {
      return comment.comment;
    },
    switchIsCut(comment) {
      comment.isCut = !comment.isCut;
    },
    timeLeft(comment) {
      if (!this.limit_time_edit) {
        return 0;
      }
      if (!this.can_edit && comment.time_to_edit && comment.time_to_edit > this.timer) {
        let left = comment.time_to_edit - this.timer;
        let fmt = '';
        if (left > 3600) {
          fmt = parseInt(left / 3600, 10) + 'h ';
          left %= 3600;
        }
        fmt += parseInt(left / 60, 10) + 'min ' + parseInt(left % 60, 10) + 'sec';
        return fmt;
      }
      return 0;
    },
    checkTimer(comment) {
      if (!this.limit_time_edit) {
        return true;
      }
      if (this.can_edit || (comment.time_to_edit && comment.time_to_edit > this.timer)) {
        return true;
      }
      return false;
    },
    canAddFile() {
      if (this.attachments.maxFiles === -1) {
        return true;
      }
      return this.attachments.maxFiles && this.attachments.maxFiles >= (
        this.attachments.fileItems.length + (this.attachments.currAttachments || []).length);
    },
    addNewAttachment() {
      const newAtt = {
        uploading: false,
        progress: 0,
        processing: false,
        showProgress: false,
        showCancel: false,
        error: 0,
        procError: false,
        showInput: true,
        rendInput: true,
        fileType: false,
        thumbnail: false,
        image: false,
        pk: false,
        model: null,
        tmpPk: false,
        tmpData: false,
      };
      const currAtt = hlp.deepCopy(newAtt);
      currAtt.idx = this.attachmentSeq;
      currAtt.name = 'file_' + currAtt.idx;
      this.attachmentSeq += 1;
      this.attachments.fileItems.push(currAtt);
    },
    uploadToS3(element) {
      const target = $('input[name=' + element.name + ']').get(0);
      const fileItemsLength = this.attachments.fileItems.length;

      if (fileItemsLength + target.files.length + this.uploadedAttachmentsCount > 6) {
        this.$root.confirm('It is not allowed to upload over 5 attachments');
      } else {
        const $this = this;
        const uploadFn = () => {
          $this.sendingInProgress = true;
          for (let i = 0; i < target.files.length; i++) {
            this.addNewAttachment();
            const inputFile = target.files[i];
            if (this.attachments.masubmitCommentxSize) {
              if (this.attachments.maxSize < inputFile.size) {
                this.$root.confirm('Error! File is too big. Max allowed size is: ' + hlp.formatFileSize(this.attachments.maxSize));
                return;
              }
            }
            $rootScope.$emit('contentModified');
            Server.post('/machina-api/new-attachment/?comment=true', {
              group: this.attachments.group,
              inp_file: inputFile.name,
              comment: true,
            })
              .then((dt2) => {
                this.attachments.fileItems[fileItemsLength - 1 + i].showProgress = true;

                const formData = new FormData();
                // formData.append('file', inputFile);
                hlp.forEach(this.attachments.s3data, (itm) => {
                  let [, v] = itm;
                  if (itm[0] === 'key') {
                    // eslint-disable-next-line
                    v = v.replace('${filename}', dt2.key);
                  } else if (itm[0] === 'file') {
                    v = inputFile;
                  } else if (itm[0] === 'content-type') {
                    v = inputFile.type;
                  }
                  formData.append(itm[0], v);
                });
                this.attachments.fileItems[fileItemsLength - 1 + i].tmpPk = dt2.pk;
                this.attachments.fileItems[fileItemsLength - 1 + i].tmpData = dt2.tmp_data;
                const xhr = new window.XMLHttpRequest();
                xhr.responseType = 'json';
                this.xhrMap[this.attachments.fileItems[fileItemsLength - 1 + i].name] = xhr;
                this.attachments.fileItems[fileItemsLength - 1 + i].showCancel = true;
                this.attachments.fileItems[fileItemsLength - 1 + i].showInput = false;

                const completeResp = (el5) => {
                  el5.pk = el5.tmpData.pk;
                  this.xhrMap[el5.name] = null;
                  const runImgLoad = (el5a, nr, src) => {
                    if (nr > 30) {
                      return;
                    }
                    this.imageMap[el5a.name] = new Image();
                    this.imageMap[el5a.name].onload = function onLoadFn() {
                      el5a.fileType = el5a.tmpData.file_type;
                      el5a.thumbnail = el5a.tmpData.thumbnail_name;
                      el5a.image = el5a.tmpData.file_name;
                      el5a.processing = false;
                      $this.sendingInProgress = false;
                      $this.$forceUpdate();
                    };
                    this.imageMap[el5a.name].onerror = function onErrFn() {
                      setTimeout(() => {
                        runImgLoad(el5a, nr + 1, src);
                      }, 1000);
                    };
                    this.imageMap[el5a.name].src = src;
                  };
                  // runImgLoad(el5, 1, el5.tmpData.file_name);
                  runImgLoad(el5, 1, el5.tmpData.thumbnail_name);
                  Server.put('/machina-api/new-attachment/', { pk: el5.pk }).then(() => {
                  });
                };
                const completeFn = (el2) => {
                  // xhrMap[el2.name] = null;
                  el2.showCancel = false;
                  setTimeout(() => {
                    this.$forceUpdate();
                    el2.rendInput = false;
                  });
                };
                xhr.upload.addEventListener('load', () => {
                  this.attachments.fileItems[fileItemsLength - 1 + i].uploading = false;
                  completeResp(this.attachments.fileItems[fileItemsLength - 1 + i]);
                  completeFn(this.attachments.fileItems[fileItemsLength - 1 + i]);
                });
                xhr.upload.addEventListener('progress', (evt2) => {
                  const percentComplete = evt2.loaded / evt2.total;
                  this.attachments.fileItems[fileItemsLength - 1 + i].progress = 100.0 * percentComplete;
                  if (this.attachments.fileItems[fileItemsLength - 1 + i].progress >= 99) {
                    this.attachments.fileItems[fileItemsLength - 1 + i].processing = true;
                    this.attachments.fileItems[fileItemsLength - 1 + i].uploading = false;
                  }
                  this.$forceUpdate();
                }, false);
                xhr.upload.addEventListener('error', () => {
                  this.attachments.fileItems[fileItemsLength - 1 + i].error = 1;
                  this.attachments.fileItems[fileItemsLength - 1 + i].uploading = false;
                  completeFn(this.attachments.fileItems[fileItemsLength - 1 + i]);
                }, false);
                xhr.upload.addEventListener('abort', () => {
                  this.attachments.fileItems[fileItemsLength - 1 + i].error = 2;
                  this.attachments.fileItems[fileItemsLength - 1 + i].uploading = false;
                  completeFn(this.attachments.fileItems[fileItemsLength - 1 + i]);
                }, false);
                xhr.open('POST', `//${this.attachments.bucket}.s3.amazonaws.com/`);
                xhr.send(formData);
                this.attachments.fileItems[fileItemsLength - 1 + i].uploading = true;
              });
          }
        };
        if (this.attachments.group) {
          uploadFn(element);
        } else {
          Server.get('/machina-api/attachment-group/?t=&comment=true').then((data) => {
            this.attachments.group = data.pk;
            this.attachments.s3data = data.s3data;
            this.attachments.bucket = data.bucket;
            this.attachments.maxSize = data.max_size;
            uploadFn(element);
          });
        }
      }
    },
    abortUpload(el) {
      this.xhrMap[el.name].abort();
      el.showCancel = false;
    },
    removeTmpFile(el) {
      el.processing = true;
      el.showProgress = true;
      el.fileType = false;
      el.deleting = true;

      Server.get('/machina-api/del-s3-attachment/' + el.pk + '/').then(() => {});
      Server.get('/machina-api/del-attachment/' + el.pk + '/').then((data) => {
        if (data.status === 'OK') {
          this.attachments.fileItems = this.attachments.fileItems.filter((x) => x.pk !== el.pk);
        }
      });
    },
    removeAttachedFile(index, ind, pk) {
      if (this.commentList.length > index && this.commentList[index].medias.length > ind) {
        this.$root.confirm('Do you want to delete this attachment?').then((accept) => {
          if (accept) {
            const tmpMedia = { ...this.commentList[index].medias[ind], ...{ removeProcessing: true } };
            this.commentList[index].medias.splice(ind, 1);
            this.commentList[index].medias.splice(ind, 0, tmpMedia);
            this.removePKs.push(pk);

            Server.post('api/comments/edit/del-s3/' + parseInt(this.commentList[index].id, 10) + '/', { pks: [pk] })
              .then((data) => {
                if (data.status === 'OK') {
                  this.commentList[index].medias.splice(ind, 1);
                }
              });

            Server.post('/api/comments/edit/' + parseInt(this.commentList[index].id, 10) + '/',
              { comment: this.commentList[index].editValue, pks: this.removePKs, group: this.attachments.group })
              .then(() => {});
          }
        });
      }
    },
    beforeDestroy() {
      if (this.ticker) {
        clearInterval(this.ticker);
      }
    },
    loadMoreComments() {
      this.showingCount += 10;
      if (this.showingCount >= this.commentsCount) this.isLoadMoreAvailable = false;
    },
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.beforeUnloadFn);
  },
};
