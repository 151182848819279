<template>
  <div>
    <b-modal :id="myId" :no-stacking="stopStacking" hide-footer>
      <div class="form-container">
        <div class="modal-header">
          <h3 id="billing_info">Billing Info <img src="/static/img/creditCards.png"></h3>
        </div>
        <div class="form-container">
          <form id="payment-form" class="form-horizontal" v-stripe-form :ajax-url="submiturl"
                success-eval="submitComplete">
            <div class="modal-body">
              <fieldset>
                <label for="card-element">
                  Credit or debit card:
                </label>
                <div id="card-element">
                  <!-- A Stripe Element will be inserted here. -->
                </div>
                <!-- Used to display form errors. -->
                <div id="card-errors" class="text-danger" role="alert"></div>
              </fieldset>
              <div class="buttons-row text-right mt-3">
                <a class="btn btn-small btn-primary stripe-submit-btn">
                  <span class="active-cont">OK</span>
                  <span class="sending-cont">
                    <b-icon-arrow-repeat animation="spin"></b-icon-arrow-repeat>
                    Processing...
                  </span>
                </a>
                <a class="btn btn-small btn-secondary" @click="closeModal()">Cancel</a>
              </div>
            </div>
          </form>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import baseModalComponent from '&/utils/baseModalComponent.js';
import * as helperMod from '&/utils/helpers';
import * as directives from '&/directives/DirectiveBase';
import { BIconArrowRepeat } from 'bootstrap-vue';

const {
  stripeForm,
} = directives;

const hlp = helperMod.appHelper;
const $ = require('jquery');

const { Server } = helperMod;

(() => {})(hlp.consoleLog, $, Server);
export default {
  name: 'modalCreditCard',
  mixins: [baseModalComponent],
  props: ['submiturl', 'stripekey'],
  directives: { stripeForm },
  components: { BIconArrowRepeat },
  data() {
    return {
      popupKey: 'modalcreditcard',
      stripe: false,
    };
  },
  mounted() {
  },
  methods: {
    onCreated() {
    },
    onHide(trigger) {
      (() => {})(trigger);
    },
    submitComplete() {
      this.closeModal();
      window.location.reload();
    },
    onShow() {
      if (!window.stripe) {
        window.stripe = window.Stripe(this.stripekey);
      }
    },
  },
};
</script>
