<template>
  <div>
    <b-modal :id="myId">
      <div class="att-modal-content">
        <div class="att-modal-wrapper">
          <div class="att-cont att-image-cont" v-if="collection.showImage">
            <img :src="collection.showImage" style="max-width: 100%; max-height: 100%;"
                 v-img-orientation img-orientation=".reveal.gsa-modal">
          </div>
          <div class="att-cont att-video-cont" v-if="collection.showVideos && collection.showVideos.length">
            <video controls autoplay :data-fakefn="rmClass('.reveal.gsa-modal', 'img-orient-horizontal img-orient-vertical img-orient-overflow')">
              <source :src="v" v-for="v in collection.showVideos" :key="v">
            </video>
          </div>
          <div class="att-cont att-file-cont" v-if="collection.showFile">
            <a :href="collection.showFile" v-text="collection.showFile"></a>
          </div>
        </div>
      </div>

    </b-modal>
  </div>
</template>
<script>
import baseModalComponent from '&/utils/baseModalComponent.js';
import * as helperMod from '&/utils/helpers';
import * as PfBase from '&/utils/base';

const hlp = helperMod.appHelper;
const $ = require('jquery');

const { $rootScope } = PfBase;

(() => {})(hlp.consoleLog, $);

export default {
  name: 'machinaAttachment',
  mixins: [baseModalComponent],
  props: ['collection'],
  data() {
    return {
      popupKey: 'machinaattachment',
    };
  },
  methods: {
    onHide() {
      $rootScope.$emit('attachment-view-modal-close');
    },
    rmClass(el, clss) {
      $(el).removeClass(clss);
    },
  },
};
</script>
