<template>
<div class="text-center">
  <nav class="pager d-inline-block ">
    <ul class="pagination ustify-content-center" aria-label="Pagination" v-if="totalPages && totalPages > 1">
      <li class="page-item" :class="{disabled: currPage == 0}">
        <span class="page-link" aria-label="Previous" v-if="currPage == 0">
          <span aria-hidden="true">&laquo;</span>
          <span class="sr-only">Previous</span>
        </span>
        <a class="page-link" @click="changePage(currPage-1)" aria-label="Previous" v-if="currPage > 0">
          <span aria-hidden="true">&laquo;</span>
          <span class="sr-only">Previous</span>
        </a>
      </li>

      <li class="page-item">
        <a class="page-link" @click="changePage(0)" v-if="showFirst">1</a>
      </li>
      <li class="ellipsis page-item disabled" v-if="showFirstEllipsis">
        <span class="page-link">&hellip;</span>
      </li>
      <li class="page-item" v-for="pg in pageList" :class="{active: pg == currPage}" :key="pg"><!-- &#8230; -->
        <a class="page-link" v-text="pg + 1" @click="changePage(pg)"></a>
      </li>
      <li class="ellipsis page-item disabled" v-if="showLastEllipsis">
        <span class="page-link">&hellip;</span>
      </li>
      <li class="page-item">
        <a class="page-link" @click="changePage(totalPages-1)" v-if="showLast" v-text="totalPages"></a>
      </li>
      <li class="page-item" :class="{disabled: currPage >= totalPages-1}">
        <a class="page-link" @click="changePage(currPage+1)" v-if="currPage < totalPages-1">
          <span aria-hidden="true">&raquo;</span>
          <span class="sr-only">Next</span>
        </a>
        <span v-if="currPage >= totalPages-1" class="page-link">
          <span aria-hidden="true">&raquo;</span>
          <span class="sr-only">Next</span>
        </span>
      </li>
    </ul>
  </nav>
</div>
</template>
<script>
import * as helperMod from '&/utils/helpers';

const helpers = helperMod.appHelper;

const $ = require('jquery');

(() => {})(helpers.consoleLog, $);

export default {
  name: 'simplePager',
  mixins: [],
  props: ['page', 'limit', 'count'],
  data() {
    return {
      currPage: false,
      totalPages: false,
      maxPage: false,
      minPage: false,
      pageList: false,
      showFirst: false,
      showFirstEllipsis: false,
      showLast: false,
      showLastEllipsis: false,
    };
  },
  created() {
    this.initPager();
  },
  watch: {
    count() {
      this.initPager();
    },
    page() {
      this.initPager();
    },
  },
  methods: {
    initPager() {
      this.currPage = this.page;
      this.totalPages = Math.ceil(this.count / this.limit);
      this.maxPage = Math.min(this.currPage + 5, this.totalPages);
      this.minPage = Math.max(0, this.currPage - 5);
      this.pageList = [];
      for (let x = this.minPage; x < this.maxPage; x += 1) {
        this.pageList.push(x);
      }
      this.showFirst = this.minPage > 0;
      this.showFirstEllipsis = this.minPage > 1;
      this.showLast = this.maxPage < this.totalPages;
      this.showLastEllipsis = this.maxPage < this.totalPages - 1;
    },
    changePage(nr) {
      this.currPage = nr;
      this.$emit('pagechanged', nr);
    },
  },
};
</script>
