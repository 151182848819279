import * as PfBase from '&/utils/base';
import * as helpers from '&/utils/helpers';
import baseCtrlMixIn from '&/utils/baseController';
import jquery from 'jquery';

const $ = jquery;
const { $rootScope } = PfBase;

const hlp = helpers.appHelper;
const { Server } = helpers;

(() => {})(hlp.consoleLog, $, $rootScope, Server);

const CtrlFixYourGolf = {
  name: 'CtrlFixYourGolf',
  el: '.ctrl-fix-your-golf',
  components: {
  },
  mixins: [baseCtrlMixIn],
  data() {
    return {
      useremail: '',
      emailSent: false,
      emailSending: false,
      showError: false,
      errorType: '',
    };
  },
  created() {
  },
  methods: {
    sendEmail() {
      // this.useremail
      this.emailSending = true;
      this.showError = false;
      Server.post('api/subscribe/', { email: this.useremail }).then((resp) => {
        this.emailSending = false;
        if (resp.status === 'OK') {
          this.emailSent = true;
          this.useremail = '';
        } else {
          this.showError = true;
          this.errorType = resp.error;
        }
      });
      setTimeout(() => {
      }, 2000);
    },
  },
};
export default CtrlFixYourGolf;
