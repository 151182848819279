import * as helpers from '&/utils/helpers';
import jquery from 'jquery';

const $ = jquery;

const hlp = helpers.appHelper;
const { Server } = helpers;

(() => {})(hlp.ConsoleLog, $);

const PromoCodeMixIn = {
  components: {
  },
  mixins: [],
  data() {
    return {
      promoCode: false,
      promoDescription: '',
      promoExtraDescription: '',
      promoActive: false,
      promoAdjustAmountPercent: false,
      promoAdjustAmountAbs: false,
      promoDuration: false,
      promoDurationInMonths: false,
      promoFetching: false,
      promoFormActive: true,
      promoInput: '',
      errorReason: false,
      promoProd: '',
      promoInited: false,
    };
  },
  created() {
    this.initPromoCode();
    this.$nextTick(() => {
      this.initPromoData();
    });
  },
  methods: {
    initPromoCode() {
      const promoParam = (window.location.search.split('?')[1] || '').split('&').filter((x) => x.indexOf('promo=') === 0);
      this.promoCode = '';
      if (promoParam && promoParam.length) {
        this.promoCode = decodeURIComponent(promoParam[0].split('=')[1] || '');
        this.promoInput = this.promoCode;
      }
    },
    initPromoData() {
      if (this.promoCode && this.promoProd && !this.promoInited) {
        this.getPromoData();
      }
    },
    getPromoData() {
      this.promoInited = true;
      this.promoFetching = true;
      Server.get('api/promo-code/', { promo: this.promoCode, product: this.promoProd }).then((resp) => {
        this.promoFetching = false;
        if (resp.status === 'OK') {
          this.promoActive = true;
          this.promoDescription = resp.description;
          this.promoExtraDescription = resp.extra_description;
          this.promoAdjustAmountPercent = resp.percent_discount;
          this.promoAdjustAmountAbs = resp.amount_discount;
          this.promoDuration = resp.duration;
          this.promoDurationInMonths = resp.duration_in_months;
          // this.promoFormActive = false;
          // this.promoInput = '';
        } else if (resp.status === 'failed') {
          this.errorReason = resp.reason;
        }
        // console.log(resp);
      });
    },
    removePromo() {
      this.promoCode = false;
      this.promoActive = false;
      this.promoDescription = '';
      this.promoAdjustAmountPercent = false;
      this.promoAdjustAmountAbs = false;
      this.promoDuration = false;
      this.promoDurationInMonths = false;
      this.promoInput = '';
    },
    switchPromoForm() {
      this.promoFormActive = !this.promoFormActive;
    },
    applyPromo() {
      this.promoCode = this.promoInput;
      this.errorReason = false;
      this.promoActive = false;
      this.getPromoData();
    },
    urlExtendPromoParam(baseUrl) {
      let url = baseUrl;
      if (this.promoActive) {
        const sep = baseUrl.indexOf('?') > -1 ? '&' : '?';
        url += sep + `promo=${escape(this.promoCode)}`;
      }
      return url;
    },
    setProduct(prodKey) {
      this.promoProd = prodKey;
      this.$nextTick(() => {
        this.initPromoData();
      });
    },
    adjustAmount(originalAmount) {
      const origAmount = parseFloat(originalAmount.toString().trim().replace(',', '.'));
      let newAmount = origAmount;
      if (this.promoAdjustAmountPercent) {
        newAmount -= (origAmount * this.promoAdjustAmountPercent) / 100.0;
      } else if (this.promoAdjustAmountAbs) {
        newAmount -= this.promoAdjustAmountAbs;
      }
      newAmount = Math.min(origAmount, Math.max(newAmount, 0));
      return newAmount.toFixed(2);
    },
  },
};
export default PromoCodeMixIn;
