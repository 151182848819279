import * as PfBase from '&/utils/base';
import * as helpers from '&/utils/helpers';
import baseCtrlMixIn from '&/utils/baseController';
import jquery from 'jquery';

const $ = jquery;
const { $rootScope } = PfBase;

const hlp = helpers.appHelper;
const { Server } = helpers;

(() => {})(hlp.consoleLog, $);

const CtrlTreeNodeDecision = {
  name: 'CtrlTreeNodeDecision',
  el: '.ctrl-tnd-controller',
  components: {
  },
  mixins: [baseCtrlMixIn],
  data() {
    return {
      showSignup: false,
      showSignin: false,
      currentPk: false,
      authSending: false,
      isRedirecting: false,
      decisionNodeTreeData: {
        currentData: false,
        selectedAnswer: false,
        helpModalData: {},
      },
      authErrors: {
        non_field_errors: '',
        email: '',
        password: '',
      },
      signInForm: {
        email: '',
        password: '',
      },
      signUpForm: {
        first_name: '',
        last_name: '',
        email: '',
        password: '',
      },
    };
  },
  created() {
    $rootScope.$on('mulliganGoStraightToPlaylist', (evt) => {
      this.isRedirecting = true;
      if (this.getIsAuthenticated() && evt.data.url) {
        window.location.href = evt.data.url;
      } else {
        this.isRedirecting = false;
      }
    });
  },
  methods: {
    getTreeNodeDecision(pk) {
      this.currentPk = pk;
      Server.get(`/api/decision-node-tree/${pk}/`).then((data) => {
        this.decisionNodeTreeData.currentData = data;
        const { answers } = this.decisionNodeTreeData.currentData;
        this.decisionNodeTreeData.currentData.answers = [];
        setTimeout(() => {
          this.decisionNodeTreeData.currentData.answers = answers;
          this.$forceUpdate();
        }, 200);
        // this.$nextTick(() => {
        //   this.decisionNodeTreeData.currentData.answers = answers;
        // });
        /*
        setTimeout(() => {
          document.scrollTop(document.height());
        }, 0);
        */
        this.showSignup = data.require_signup;
        this.showSignin = false;
        if (this.showSignup && ((data.prev_answer || {}).playlist || {}).url) {
          this.addRefreshEvent('mulliganGoStraightToPlaylist', { url: data.prev_answer.playlist.url });
        }
        // (!data.question || !data.question.text) && data.is_last
        if (!(this.showSignup || this.showSignin) && (!data.question || !data.question.text) && data.is_last) {
          this.isRedirecting = true;
          window.location.href = data.prev_answer.playlist.url;
        }
      });
      this.decisionNodeTreeData.selectedAnswer = false;
    },
    switchAuthMode() {
      this.authErrors = { email: '', password: '', non_field_errors: '' };
      this.showSignup = !this.showSignup;
      this.showSignin = !this.showSignup;
    },
    openNodeTreeHelp(evt, item) {
      this.decisionNodeTreeData.helpModalData = item;
      this.showModal('#quiz-help-modal');
      evt.preventDefault();
      evt.stopPropagation();
    },
    authFormInvalid() {
      return (this.showSignup) ? this.signUpInvalid() : this.signInInvalid();
    },
    signUpInvalid() {
      let isValid = true;
      const names = ['first_name', 'last_name', 'email', 'password'];
      hlp.forEach(names, (name) => {
        isValid = isValid && this.signUpForm[name];
      });
      return !isValid;
    },
    signInInvalid() {
      return !this.signInForm.email || !this.signInForm.password;
    },
    submitAuthForm() {
      const endpointPart = (this.showSignup) ? 'signup' : 'signin';
      const dataToSend = (this.showSignup) ? this.signUpForm : this.signInForm;
      this.authErrors = { email: '', password: '', non_field_errors: '' };
      this.authSending = true;
      Server.post(`/api/mulligan/${endpointPart}/${this.currentPk}/`, dataToSend).then((data) => {
        if (data.status === 'OK') {
          this.getTreeNodeDecision(this.currentPk);
        } else {
          this.authSending = false;
          this.authErrors = data.errors;
        }
      });
    },
    getQuizMinHeight() {
      const currData = this.decisionNodeTreeData.currentData;
      if (currData && currData.answers && currData.answers.length) {
        return { 'min-height': (currData.answers.length * 6.1) + 'em' };
      }
      return {};
    },
  },
};
export default CtrlTreeNodeDecision;
