<template>
<div class="overlay-content">'
  <div class="wrapper">
    <div class="content">
      <slot></slot>
    </div>
  </div>
</div>
</template>
<script>
export default {
  name: 'overlayBox',
  data() {
    return {
    };
  },
  created() {
  },
};
</script>
